<script lang="ts" setup>
// @ts-ignore
import RouteStops from "@/views/trips/view/components/RouteStops.vue";
// @ts-ignore
import RouteInfo from "@/views/trips/view/components/RouteInfo.vue";
import type { TripDto } from "@/api/trips/dto/trip.dto";

const props = defineProps<{
  trip: TripDto;
}>();

const emit = defineEmits(["change"]);

function onChange() {
  emit("change");
}
</script>

<template>
  <v-row class="mt-4">
    <v-col v-for="route in props.trip.routes" :key="'info-' + route.id" lg="6" xl="4">
      <route-info :route="route"></route-info>
      <route-stops :route="route" :trip="props.trip" @change="onChange"></route-stops>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped></style>
