<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    id?: string;
    modelValue?: string | number | undefined;
    label?: string;
    rules?: Array<any>;
    required?: boolean;
    disabled?: boolean;
  }>(),
  {
    modelValue: "",
    label: "",
    rules: () => [],
    required: false,
    disabled: false,
  },
);

const emit = defineEmits(["update:modelValue"]);

const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <v-text-field
    :id="props.id"
    v-model="model"
    :class="{ required: props.required }"
    :disabled="props.disabled"
    :label="props.label"
    :rules="props.rules"
    autocomplete="off1"
    color="primary"
    density="compact"
    hide-details="auto"
    variant="outlined"
  />
</template>

<style lang="scss" scoped></style>
