<script>
import { OrdersMixin } from "@/views/orders/orders.mixin";

export default {
  name: "OrderStopsList",
  mixins: [OrdersMixin],
  props: {
    distances: Object,
    stops: Array,
  },
};
</script>

<template>
  <table class="order-stops-list__table">
    <template v-for="(stop, i) in stops" :key="stop.id">
      <tr :class="{ 'pick-up': stop.type === STOP_PICK_UP, delivery: stop.type === STOP_DELIVERY }">
        <td class="order-stop__type">
          <div class="d-flex align-center">
            <v-icon v-if="stop.type === STOP_PICK_UP" class="order-stop__icon mr-2 text-green" size="16">
              mdi-package-variant-closed
            </v-icon>
            <v-icon
              v-if="stop.type === STOP_DELIVERY"
              class="order-stop__icon mr-2 text-red text--red-accent-2"
              size="16"
            >
              mdi-map-marker-radius-outline
            </v-icon>
            <span class="font-size-12 blue-text-grey-darken-1 font-weight-medium" data-qa="stop-type">
              {{ stop.name }}
            </span>
          </div>
        </td>
        <td class="order-stop__name flexible">
          <router-link
            :to="{ name: 'facilities-edit', params: { id: stop.facilityId } }"
            class="text-primary text-decoration-none font-weight-medium d-block ellipsize"
          >
            {{ stop.facility.name }}
          </router-link>
        </td>
        <td class="order-stop__address flexible">
          <div class="d-flex align-baseline">
            <v-icon class="mr-2 text-grey-darken-2" size="14">mdi-map-marker-radius-outline</v-icon>
            <span class="text-grey-darken-2 font-weight-medium ellipsize ellipsize-start">
              {{ stop.facility.address }}
            </span>
          </div>
        </td>
        <td class="order-stop__time flexible">
          <div class="d-flex">
            <span class="text-grey-darken-1 mr-2">{{ getTimeType(stop.timeType) }}:</span>
            <span class="text-grey-darken-2 font-weight-medium ellipsize">{{ getStopTime(stop) }}</span>
          </div>
        </td>
      </tr>
      <tr v-if="i < stops.length - 1" class="distance">
        <td class="pa-0" colspan="4">
          <div class="distance-gap">
            <div class="distance-gap--distance">{{ getStopDistance(stops, distances, i) }} miles</div>
            <div class="distance-gap--line"></div>
          </div>
        </td>
      </tr>
    </template>
  </table>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.order-stops-list__table {
  border: none !important;
  border-collapse: collapse;
  width: 100%;

  tr,
  td {
    border: none !important;
    background-color: transparent !important;
  }

  td {
    padding: 0 24px 0 0 !important;
    height: 42px !important;

    &.flexible {
      max-width: 0;
    }
  }

  .order-stop__type {
    width: 130px;
  }

  .order-stop__address {
    width: 40%;
  }

  .order-stop__time {
    width: 30%;
  }

  tr:not(:last-child) {
    .order-stop__icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 20px;
        height: 18px;
        border-left: 1px dotted;
        color: $dark-grey;
      }
    }
  }
}

.distance-gap {
  position: relative;
  margin: 10px 0 10px 44px;
  height: 12px;

  .distance-gap--distance {
    position: absolute;
    background-color: #ffffff;
    padding: 0 12px;
    top: -5px;
    left: 45%;
    font-size: 10px;
    font-weight: 400;
    z-index: 10;
  }

  .distance-gap--line {
    position: absolute;
    top: 5px;
    height: 1px;
    width: 100%;
    background-color: #e0e0e0;
  }
}
</style>
