<script lang="ts" setup>
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import type { CheckCallDTO } from "@/api/trips/dto/check-call";
import { useAppStore } from "@/store/app.store";

const dateUtils = DateTimeMixin.methods!;

const appStore = useAppStore();

defineProps<{
  checkCall: CheckCallDTO;
}>();
</script>

<template>
  <div class="text-grey-darken-3 font-weight-500" data-qa="cc-tab-date-1">
    {{ dateUtils.dateTZ(checkCall.checkCallTime, appStore.dispatcher!.timezone) }}
  </div>
  <div class="text-grey-darken-1" data-qa="cc-tab-date-2">
    {{ dateUtils.dateTZ(checkCall.checkCallTime, checkCall.timezone) }}
  </div>
  <div class="text-grey-darken-1 font-size-12 font-weight-500" data-qa="time-from-event">
    {{ dateUtils.timeAgo(checkCall.checkCallTime) }}
  </div>
</template>
