<script>
import OmniDialog from "@/components/OmniDialog.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import FileUploadingGrid from "@/components/FileUploadingGrid.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { OrdersMixin } from "@/views/orders/orders.mixin";
import BaseView from "@/views/BaseView.vue";

export default {
  name: "DeliveryInfoDialog",
  components: { FileUploadingGrid, OmniSelect, TimePicker, DatePicker, OmniDialog },
  mixins: [ValidationMixin, OrdersMixin, BaseView],
  props: {
    title: String,
    modelValue: Boolean,
    preload: Boolean,
    readonly: Boolean,
    trip: Object,
    route: Object,
    stop: Object,
  },
  emits: ["update:modelValue", "save"],
  data() {
    return {
      loading: false,
      unloadData: {
        date: null,
        time: null,
        timezone: null,
        signedBy: null,
        podFiles: [],
        files: [],
      },
    };
  },
  computed: {
    freights() {
      return this.route.travelOrders.flatMap((to) => to.$order?.freights || []);
    },
    stopFreights() {
      return this.stop.freights.map((sf) => this.freights.find((f) => f.id === sf)).filter((f) => f !== undefined);
    },
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    async showDialog(value) {
      if (value) {
        if (this.preload) {
          this.unloadData.signedBy = this.stop.podSignedBy;
          this.unloadData.timezone = this.stop.timezone;
          const date = this.$dayjs(this.stop.loadUnloadTime).tz(this.stop.timezone);
          this.unloadData.date = date.format("YYYY-MM-DD");
          this.unloadData.time = date.format("HH:mm");

          const filesResp = await this.$api.trips.getTripFiles(this.trip.id);
          if (filesResp.success) {
            const stopFiles = filesResp.data
              .filter((f) => f.tags.includes("route_stop:" + this.stop.id))
              .map((f) => ({
                ...f,
                previewPath: this.getPreviewPath(f),
              }));
            this.unloadData.podFiles = stopFiles.filter((f) => f.tags.includes("pod"));
            this.unloadData.files = stopFiles.filter((f) => f.tags.includes("other"));
          }
        } else {
          this.unloadData.timezone = this.stop.facility.timezone;
          const date = this.$dayjs().tz(this.unloadData.timezone);
          this.unloadData.date = date.format("YYYY-MM-DD");
          this.unloadData.time = date.format("HH:mm");
        }
      }
    },
  },
  methods: {
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      for (const uploadingFile of this.unloadData.podFiles) {
        uploadingFile.loading = true;
        const fileResponse = await this.$api.trips.uploadRouteStopFile(
          this.trip.id,
          this.stop.id,
          "pod",
          uploadingFile.file,
          "dummy",
        );
        if (fileResponse.success) {
          Object.assign(uploadingFile, fileResponse.data);
        } else {
          uploadingFile.error = true;
        }
        uploadingFile.loading = false;
      }
      for (const uploadingFile of this.unloadData.files) {
        uploadingFile.loading = true;
        const fileResponse = await this.$api.trips.uploadRouteStopFile(
          this.trip.id,
          this.stop.id,
          "other",
          uploadingFile.file,
          "dummy",
        );
        if (fileResponse.success) {
          Object.assign(uploadingFile, fileResponse.data);
        } else {
          uploadingFile.error = true;
        }
        uploadingFile.loading = false;
      }
      const response = await this.$api.trips.unloadRouteStop(this.trip.id, this.stop.id, {
        unloadTime: this.dateToUTC(this.unloadData.date + " " + this.unloadData.time, this.unloadData.timezone),
        podFiles: this.unloadData.podFiles,
        otherFiles: this.unloadData.files,
        podSignedBy: this.unloadData.signedBy,
      });
      if (response.success) {
        this.$emit("save");
        this.showDialog = false;
      } else {
        this.showSnackbarError("Error unload verification at stop");
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    :disabled="loading"
    :loading="loading"
    :primary-action-label="readonly ? null : 'Save'"
    :title="title"
    size="large"
    @primary-action:click="save"
  >
    <v-form ref="form" class="py-6">
      <div class="mb-6">
        <div class="font-size-16 font-weight-500 mb-6">POD info:</div>
        <div class="text-grey-darken-1 mb-3">Freight(s):</div>
        <div class="mb-6">
          <div
            v-for="freight in stopFreights"
            :key="freight.id"
            class="font-size-16 font-weight-500 text-grey-darken-3 mb-2"
            data-qa="freights-to-delivery"
          >
            {{ getFreightName(freight) }}
          </div>
        </div>
        <v-text-field
          v-model="unloadData.signedBy"
          :disabled="readonly"
          :rules="[requiredValidator]"
          class="required"
          color="primary"
          data-qa="signed-by-field"
          density="compact"
          hide-details="auto"
          label="Signed by"
          variant="outlined"
        />
      </div>
      <div class="mb-6">
        <div class="font-size-16 font-weight-500 mb-4">Attached files</div>
        <div class="text-grey-darken-1 font-weight-500 mb-4">POD files<span class="text-error">*</span></div>
        <file-uploading-grid
          v-model="unloadData.podFiles"
          :columns="3"
          :disabled="readonly"
          required
        ></file-uploading-grid>
        <div class="text-grey-darken-1 font-weight-500 mb-4 mt-6">Other files</div>
        <file-uploading-grid v-model="unloadData.files" :columns="3" :disabled="readonly"></file-uploading-grid>
      </div>
      <div>
        <div class="font-size-16 font-weight-500 mb-4">Time</div>
        <v-row>
          <v-col cols="3">
            <omni-select
              v-model="unloadData.timezone"
              :disabled="readonly"
              :items="timezonesShortList"
              :rules="[requiredValidator]"
              data-qa="timezone"
              label="Time zone"
              required
            />
          </v-col>
          <v-col cols="6">
            <date-picker v-model="unloadData.date" :disabled="readonly" label="Date" required></date-picker>
          </v-col>
          <v-col cols="3">
            <time-picker v-model="unloadData.time" :disabled="readonly" label="Time" required></time-picker>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
