import { defineComponent, getCurrentInstance } from "vue";
import { Dayjs } from "@/plugins/dayjs";

export const DateTimeMixin = defineComponent({
  data() {
    return {
      // supported timezones
      systemDateTimeFormat: "YYYY-MM-DDTHH:mm:ss",
      timezones: [
        "America/New_York",
        "America/Chicago",
        "America/Denver",
        "America/Phoenix",
        "America/Los_Angeles",
        "America/Anchorage",
      ],
    };
  },
  computed: {
    timezonesList() {
      return this.timezones.map((tz) => {
        return {
          id: tz,
          name: this.getLongTZForDate(tz),
        };
      });
    },
    timezonesShortList() {
      return this.timezones.map((tz) => {
        return {
          id: tz,
          name: this.getTZNames(tz),
        };
      });
    },
  },
  methods: {
    getTZNames(tz: string): string {
      const startDate = new Date(2000, 1, 1);
      const endDate = new Date(2000, 7, 1);
      return `${this.getShortTZForDate(startDate, tz)}/${this.getShortTZForDate(endDate, tz)}`;
    },

    getLongTZForDate(tz: string): string {
      return `${this.getTZNames(tz)} (${tz.replace("_", " ")})`;
    },

    getShortTZForDate(date: Date, tz: string) {
      if (!tz) return "";

      let locale;
      if (tz.includes("Europe")) {
        locale = "en-GB";
      } else if (tz.includes("America")) {
        locale = "en-US";
      } else {
        locale = "en";
      }

      const [, tzName] = /.*\s(.+)/.exec(
        date.toLocaleString(locale, {
          timeZone: tz,
          timeZoneName: "short",
        }),
      ) as string[];
      return tzName;
    },
    timeAgo(date: string | Date) {
      const app = getCurrentInstance();
      const dayjs = app?.appContext.config.globalProperties.$dayjs;

      let diff = dayjs().diff(dayjs(date), "minutes");
      const isPast = diff >= 0;
      if (diff === 0) {
        return "recently";
      }
      const chunks = [];
      const days = Math.floor(diff / (60 * 24));
      if (days !== 0) {
        chunks.push(Math.abs(days) + " " + (days === 1 ? "day" : "days"));
        diff -= days * 24 * 60;
      }
      const hours = Math.floor(diff / 60);
      if (hours !== 0) {
        chunks.push(Math.abs(hours) + " " + (hours === 1 ? "hour" : "hours"));
        diff -= hours * 60;
      }
      if (diff !== 0) {
        chunks.push(Math.abs(diff) + " " + (diff === 1 ? "minute" : "minutes"));
      }
      return isPast ? chunks.join(" ") + " ago" : "In " + chunks.join(" ") + " (future)";
    },
    dateTZ(dt: string | Date, tz: string, format: string = "MM/DD/YYYY HH:mm z") {
      if (!dt) return "";
      const dm = tz ? Dayjs.utc(dt).tz(tz) : Dayjs.utc(dt);
      return dm ? dm.format(format) : "";
    },
    dateToUTC(dt: string | Date, tz: string) {
      return Dayjs.tz(dt, tz).utc();
    },
    dateToUTCSystemFormat(dt: string | Date, tz: string) {
      this.dateToUTC(dt, tz).format(this.systemDateTimeFormat);
    },
    nowTZ(timezone: string) {
      return Dayjs().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    },
    fromTZToTZ(dt: string | Date, from: string, to: string, format: string) {
      return Dayjs.tz(dt, from).tz(to).format(format);
    }
  },
});
