<script>
import { OrderFreightType, OrderStopType } from "@/data/order";

export default {
  name: "RouteStopFreightDetails",
  props: {
    freights: Array,
    stop: Object,
    disabled: Boolean,
  },
  computed: {
    showDiff() {
      return this.stop.type === OrderStopType.pickup && (this.stop.loadUnloadTime || this.stop.verifyTime);
    },
    plannedQuantityPieces() {
      return this.freights.reduce((sum, f) => sum + (f.type === OrderFreightType.pieces ? f.plannedQuantity : 0), 0);
    },
    quantityPieces() {
      return this.freights.reduce((sum, f) => sum + (f.type === OrderFreightType.pieces ? f.quantity : 0), 0);
    },
    plannedQuantityPallets() {
      return this.freights.reduce((sum, f) => sum + (f.type === OrderFreightType.pallets ? f.plannedQuantity : 0), 0);
    },
    quantityPallets() {
      return this.freights.reduce((sum, f) => sum + (f.type === OrderFreightType.pallets ? f.quantity : 0), 0);
    },
    plannedWeight() {
      return this.freights.reduce((sum, f) => sum + f.plannedWeight, 0);
    },
    weight() {
      return this.freights.reduce((sum, f) => sum + f.weight, 0);
    },
    quantityPalletsClass() {
      if (this.disabled) {
        return "text-grey";
      }
      if (this.quantityPallets > this.plannedQuantityPallets) {
        return "text-error";
      } else if (this.quantityPallets < this.plannedQuantityPallets) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    quantityPiecesClass() {
      if (this.disabled) {
        return "text-grey";
      }
      if (this.quantityPieces > this.plannedQuantityPieces) {
        return "text-error";
      } else if (this.quantityPieces < this.plannedQuantityPieces) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    weightClass() {
      if (this.disabled) {
        return "text-grey";
      }
      if (this.weight > this.plannedWeight) {
        return "text-error";
      } else if (this.weight < this.plannedWeight) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
  },
};
</script>

<template>
  <div :class="disabled ? 'text-grey' : 'text-grey-darken-2'" class="d-flex align-center mt-6 font-weight-500">
    <svg height="20" width="20">
      <use xlink:href="@/assets/images/pallet.svg#pallet"></use>
    </svg>
    <div class="ml-2" data-qa="route-stop-planned-freight-quantity">
      <template v-if="showDiff">
        <div v-if="plannedQuantityPallets">
          {{ plannedQuantityPallets }} /
          <span :class="quantityPalletsClass" data-qa="rote-stops-actual-pallets">{{ quantityPallets }}</span> pallets
        </div>
        <div v-if="plannedQuantityPieces">
          {{ plannedQuantityPieces }} /
          <span :class="quantityPiecesClass" data-qa="rote-stops-actual-pieces">{{ quantityPieces }}</span> pieces
        </div>
      </template>
      <template v-else>
        <div v-if="plannedQuantityPallets">{{ plannedQuantityPallets }} pallets</div>
        <div v-if="plannedQuantityPieces">{{ plannedQuantityPieces }} pieces</div>
      </template>
    </div>
    <svg class="ml-4" height="20" width="20">
      <use xlink:href="@/assets/images/weight.svg#weight"></use>
    </svg>
    <div class="ml-2" data-qa="route-stop-planned-freight-weight">
      <template v-if="showDiff"
        >{{ plannedWeight }} / <span :class="weightClass">{{ weight }}</span> lbs
      </template>
      <template v-else>{{ plannedWeight }} lbs</template>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
