<script lang="ts" setup>
import { computed } from "vue";
import { useAppStore } from "@/store/app.store";
import { DialogSize } from "@/components/types";

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    title: string;
    subtitle?: string;
    primaryActionLabel?: string;
    secondaryActionLabel?: string;
    size?: DialogSize;
    width?: string;
    height?: string;
    hideActions?: boolean;
    disabled?: boolean;
    loading?: boolean;
    persistent?: boolean;
  }>(),
  {
    size: DialogSize.normal,
    hideActions: false,
    disabled: false,
    loading: false,
    persistent: false,
  },
);

const emit = defineEmits(["update:modelValue", "primaryAction:click", "secondaryAction:click"]);

const appStore = useAppStore();
const mobile = computed(() => appStore.mobile);

const isVisible = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit("update:modelValue", value),
});

const dialogWidth = computed(() => {
  if (props.width) return props.width;
  switch (props.size) {
    case DialogSize.small:
      return 400;
    case DialogSize.normal:
      return 600;
    case DialogSize.large:
      return 900;
    case DialogSize.xlarge:
      return 1200;
    case DialogSize.xxlarge:
      return 1500;
    default:
      return props.size;
  }
});

const dialogHeight = computed(() => props.height);

function closeDialog() {
  isVisible.value = false;
}

function clickPrimaryAction() {
  emit("primaryAction:click");
}

function clickSecondaryAction() {
  emit("secondaryAction:click");
}
</script>

<template>
  <v-dialog
    v-model="isVisible"
    :fullscreen="mobile"
    :height="dialogHeight"
    :persistent="props.persistent"
    :width="dialogWidth"
    class="omni-dialog"
    scrollable
    transition="dialog-top-transition"
  >
    <v-card :class="{ dialog__open: isVisible }" :loading="props.loading">
      <v-card-title class="pa-6 pb-0 d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <div class="font-size-20 font-weight-500 text-grey-darken-4 line-height-24px text-wrap mr-6">
            {{ props.title }}
          </div>
          <slot name="title:append"></slot>
        </div>
        <v-btn density="comfortable" icon="mdi-close" size="small" variant="flat" @click="closeDialog"></v-btn>
      </v-card-title>
      <v-card-subtitle v-if="props.subtitle" class="pa-0 font-size-14 text-grey-darken-3 line-height-18px">
        {{ props.subtitle }}
      </v-card-subtitle>
      <v-card-text class="pa-6">
        <slot></slot>
      </v-card-text>
      <v-card-actions v-if="!props.hideActions" class="pa-6 pt-0 omni-dialog__actions">
        <slot name="actions:custom"></slot>
        <v-spacer></v-spacer>
        <v-btn
          v-if="props.secondaryActionLabel"
          :disabled="props.disabled"
          class="text-uppercase font-weight-600 font-size-14 px-4"
          color="primary"
          variant="outlined"
          @click="clickSecondaryAction"
        >
          {{ props.secondaryActionLabel }}
        </v-btn>
        <v-btn
          v-if="props.primaryActionLabel"
          :disabled="props.disabled"
          class="text-uppercase font-weight-600 font-size-14 px-4"
          color="primary"
          variant="flat"
          @click="clickPrimaryAction"
        >
          {{ props.primaryActionLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
