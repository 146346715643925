<script>
import BaseView from "@/views/BaseView.vue";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import MessagesList from "@/views/chats/MessagesList.vue";
import NewMessageManager from "@/views/chats/NewMessageManager.vue";
import LanguageTag from "@/components/status/LanguageTag.vue";
import TransferChatAction from "@/views/chats/actions/TransferChatAction.vue";
import ClaimChatAction from "@/views/chats/actions/ClaimChatAction.vue";
import ResolveChatAction from "@/views/chats/actions/ResolveChatAction.vue";
import SidebarToggle from "@/components/layout/SidebarToggle.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";
import AlertMessage from "@/components/AlertMessage.vue";

export default {
  name: "MessagesPage",
  components: {
    AlertMessage,
    SidebarToggle,
    ResolveChatAction,
    ClaimChatAction,
    TransferChatAction,
    LanguageTag,
    NewMessageManager,
    MessagesList,
  },
  mixins: [BaseView, ChatMixin],
  props: {
    image: String,
    statuses: Object,
    thread: Object,
  },
  emits: ["toggleChatInfo", "hide"],
  data() {
    return {
      loading: false,
      text: "",
      isActive: true,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatchers", "mobile", "dispatcher"]),
    isFinanciallyResponsible() {
      return (
        this.thread.user.isDriver &&
        (!this.thread.user.contactPersonId || this.thread.user.contactPersonId === this.thread.user.id)
      );
    },
  },
  watch: {
    thread() {
      this.$forceUpdate();
    },
  },
  methods: {
    hideMessagesBlock() {
      this.$emit("hide");
    },
    hasOtherContactPerson(user) {
      return user.isDriver && user.isOwner && user?.contactPersonId && user?.contactPersonId !== user.id;
    },
  },
};
</script>

<template>
  <div class="messages-area__inner height-100 d-flex flex-column">
    <div class="messages-area__wrapper d-flex flex-column flex-1 overflow-hidden">
      <div class="messages-area__header-inner position-relative">
        <div class="messages-area__header pe-6 pt-7 pb-7 pa-6">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-md-center">
              <v-avatar :color="avatarColor(thread)" class="me-2" size="44">
                <span class="text-white">{{ $filters.initials(thread.name) }}</span>
              </v-avatar>
              <div>
                <div class="mb-1 d-flex align-center">
                  <h4 class="driver__name ma-0 text-grey-darken-3 font-weight-medium">
                    {{ thread.name }}
                  </h4>
                  <span v-if="isFinanciallyResponsible" class="currency-icon ml-1" />
                  <language-tag :language="thread.user.language" class="ml-1"></language-tag>
                </div>
                <div v-if="thread.user" class="user-labels d-flex flex-wrap align-center">
                  <router-link
                    v-if="thread.user.driverTruck"
                    :to="{ name: 'truck-view', params: { id: thread.user.driverTruck.id } }"
                    class="mr-2 d-flex align-center caption text-decoration-none"
                  >
                    <v-icon class="mr-1" size="14">mdi-truck-outline</v-icon>
                    <span class="text-primary font-weight-600 caption text-uppercase">
                      {{ thread.user.driverTruck.number }}
                    </span>
                  </router-link>
                  <span
                    v-if="thread.user.isDriver"
                    :class="thread.user.archived ? 'bg-grey-darken-2' : 'bg-indigo-lighten-2'"
                    class="user-label mr-2 text-uppercase font-weight-600 text-white rounded-pill pl-2 pr-2 mt-1 mb-1"
                  >
                    Driver
                  </span>
                  <span
                    v-if="thread.user.isOwner"
                    :class="thread.user.archived ? 'bg-grey-darken-2' : 'bg-green-lighten-1'"
                    class="user-label mr-2 text-uppercase font-weight-600 text-white rounded-pill pl-2 pr-2 mt-1 mb-1"
                  >
                    Owner
                  </span>
                  <span
                    v-if="thread.user.isCoordinator"
                    :class="thread.user.archived ? 'bg-grey-darken-2' : 'bg-blue-lighten-2'"
                    class="user-label mr-2 text-uppercase font-weight-600 text-white rounded-pill pl-2 pr-2 mt-1 mb-1"
                  >
                    Coordinator
                  </span>
                </div>
              </div>
            </div>
            <div v-if="thread.id" class="buttons-actions">
              <template v-if="!thread.user.archived">
                <resolve-chat-action :thread="thread" />

                <v-btn
                  v-if="thread.dispatcherId && thread.newMessagesCount > 0"
                  class="mr-2"
                  color="primary"
                  size="small"
                  variant="outlined"
                  @click="noResponseThread"
                >
                  <v-icon class="mr-1"> mdi-comment-check-outline</v-icon>
                  <span class="font-weight-600 text-uppercase text-primary"> Mark as read </span>
                </v-btn>

                <claim-chat-action :thread="thread" />
                <transfer-chat-action :thread="thread" />
              </template>
              <sidebar-toggle size="small"></sidebar-toggle>
            </div>
          </div>
          <div>
            <alert-message v-if="hasOtherContactPerson(thread.user)" class="my-2">
              Please note, user is not a contact person for himself/herself
            </alert-message>
            <div v-if="thread.dispatcher" class="mt-2 claimed-block">
              <span v-if="thread.resolved" class="text-grey-darken-3">
                {{ $t("chats.resolved") }}
              </span>
              <span v-else class="text-grey-darken-3">
                {{ $t("chats.claimed") }}
              </span>
              <span class="ml-1 rounded claim-user bg-indigo-lighten-5 text-grey-darken-3">
                {{ thread.dispatcher.name }}
              </span>
            </div>
          </div>
        </div>
        <v-btn class="square-btn rounded bg-grey-lighten-4 back-btn" @click="hideMessagesBlock">
          <v-icon class="text-grey-darken-1" size="small"> mdi-arrow-left</v-icon>
        </v-btn>
      </div>

      <messages-list ref="messagesList" :thread="thread" />
      <new-message-manager :thread="thread" @aftersent="$refs.messagesList.scrollBottom()" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.square-btn {
  width: 28px;
  height: 28px !important;
}

.messages-area__header {
  position: relative;
  z-index: 3;
  flex-shrink: 1;
  background: #fff;
  box-shadow: 0 8px 20px -7px rgba(0, 0, 0, 0.1);
  // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  @media (max-width: 991px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start !important;
    .buttons-actions {
      margin-top: 8px;
    }
  }
}

.driver__name {
  font-size: 16px;
}

.buttons-actions {
  button {
    font-size: 13px !important;
    font-weight: 400;
  }
}

.currency-icon {
  width: 18px;
  flex: 0 0 18px;
  height: 18px;
  font-size: 13px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: url(@/assets/images/currency-icon.svg) no-repeat;
  background-size: 18px;

  &.small {
    width: 14px;
    flex: 0 0 14px;
    height: 14px;
    background-size: 14px;
  }
}

.user-label {
  font-size: 12px;
  font-weight: 600;
  padding: 1px 0;
}

/*.logistic {
  display: inline-block;
  width: 16px;
  height: 12px;
  background: url(@/assets/images/logistic.svg) no-repeat;
  }*/
.claimed-block {
  font-size: 11px;
}

.claim-user {
  display: inline-block;
  padding: 2px 8px;
  // background: #E6E9FF;
  font-weight: 500;
}

.icon-logistic {
  font-size: 13px;

  &.small {
    font-size: 10px;
  }
}

.v-card {
  background: #fff !important;
}

.v-toolbar__title {
  font-size: 16px !important;
}

.v-toolbar__content {
  justify-content: center;
}

.messages-area__header-inner {
  .back-btn {
    position: absolute;
    top: 28px;
    right: 24px;
    z-index: 9;
  }
}

.back-btn {
  display: none !important;
  @media (max-width: 767px) {
    display: block !important;
  }
}

.transfer-modal {
  .v-btn:not(.v-btn--round).v-size--default {
    height: 28px !important;
  }
}

@media (max-width: 767px) {
  .messages-area__inner {
    padding: 30px 24px 40px;
  }
  .messages-area__wrapper {
    box-shadow: 0 8px 20px -7px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}
</style>
