<script lang="ts" setup>
import { DeviationStatus } from "@/api/trips/dto/check-call";
import { computed } from "vue";

const props = defineProps<{
  deviationStatus: DeviationStatus;
}>();

const statusText = computed((): string => {
  switch (props.deviationStatus) {
    case DeviationStatus.onTrack:
      return "On track";
    case DeviationStatus.deviated:
      return "Deviated";
    case DeviationStatus.wrongWay:
      return "Wrong way";
    default:
      return "";
  }
});

const statusClass = computed((): string => {
  switch (props.deviationStatus) {
    case DeviationStatus.onTrack:
      return "bg-green-lighten-1 text-white";
    case DeviationStatus.deviated:
      return "bg-amber-lighten-2 text-grey-darken-3";
    case DeviationStatus.wrongWay:
      return "bg-red-lighten-2 text-white";
    default:
      return "";
  }
});
</script>

<template>
  <div :class="statusClass" class="status-label">
    {{ statusText }}
  </div>
</template>

<style lang="scss" scoped>
.status-label {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 7px;
  font-size: 11px;
}
</style>
