<script lang="ts" setup>
import OmniDialog from "@/components/OmniDialog.vue";
//@ts-ignore
import OmniTextarea from "@/components/inputs/Textarea.vue";
//@ts-ignore
import OrderSendToInput from "@/views/orders/components/OrderSendToInput.vue";
import { DialogSize } from "@/components/types";
import { computed, ref, useTemplateRef } from "vue";
import type { VForm } from "vuetify/components";
import validationMixin from "@/mixins/validation.mixin";
import type { CreateNewUpdateRequest, PreviewUpdateResponse } from "@/api/trips/dto/update";
import type { TravelOrderDto } from "@/api/trips/dto/trip.dto";
import baseView from "@/views/BaseView.vue";
import { useSnackbarStore } from "@/store/snackbar.store";
import { API } from "@/api/api";
import type { CheckCallDTO } from "@/api/trips/dto/check-call";
import { GoogleMap } from "vue3-google-map";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";

const api = new API();

const { requiredValidator } = validationMixin.methods!;
const { scrollToError } = baseView.methods!;
const { getApiKey } = GoogleMapsMixin.methods;

const { showSnackbarError } = useSnackbarStore();

const showDialog = defineModel<boolean>({ default: false });
const props = defineProps<{
  previewInfo: PreviewUpdateResponse;
  travelOrder: TravelOrderDto;
  checkCall: CheckCallDTO;
}>();
const emits = defineEmits<{
  (e: "updateTable"): void;
  (e: "update:modelValue", value: boolean): void;
}>();

const recipients = ref<string[]>(props.previewInfo.recipients);
const messageId = ref<string>(props.previewInfo.messageId);
const subject = ref<string>(props.previewInfo.subject);
const message = ref<string>(props.previewInfo.message);
const formRef = useTemplateRef<null | VForm>("form");
const loading = ref<boolean>(false);
const mapCenter = ref();
const mapZoom = ref();

const title = computed(() => {
  return `Сreate update for Travel order#${props.travelOrder.number}`;
});

async function create() {
  const result = await formRef.value?.validate();

  if (!result?.valid) {
    scrollToError();

    return;
  }

  const { routeId, id } = props.travelOrder;

  if (!routeId || !id) {
    return;
  }

  const data: CreateNewUpdateRequest = {
    checkCallId: props.checkCall.id,
    travelOrderId: id,
    subject: subject.value,
    messageId: messageId.value,
    message: message.value,
    recipients: recipients.value,
  };

  try {
    await api.events.createUpdate(routeId, data);
    emits("update:modelValue", false);
    emits("updateTable");
  } catch (e) {
    showSnackbarError("Could not create new update");
  }
}
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    :loading="loading"
    :size="DialogSize.xlarge"
    :title="title"
    primary-action-label="Create"
    @primary-action:click="create"
  >
    <transition mode="out-in">
      <v-form ref="form" lazy-validation>
        <order-send-to-input v-model="recipients" :rules="[requiredValidator(recipients.join(''))]" />

        <v-text-field v-model="messageId" class="mt-5" density="compact" label="Message ID" variant="outlined" />

        <v-text-field
          v-model="subject"
          :rules="[requiredValidator]"
          class="required"
          density="compact"
          label="Subject"
          variant="outlined"
        />

        <omni-textarea
          v-model="message"
          :rules="[requiredValidator]"
          class="required mt-2"
          label="Message"
          rows="4"
          show-details
          variant="outlined"
        />

        <google-map
          :api-key="getApiKey()"
          :center="mapCenter"
          :fullscreen-control="false"
          :map-type-control="false"
          :street-view-control="false"
          :zoom="4"
          style="width: 100%; height: 450px"
        >
        </google-map>
      </v-form>
    </transition>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
