<script>
import TruckStatus from "@/components/status/TruckStatus.vue";
import UserStatus from "@/components/status/UserStatus.vue";
import OpenChatButton from "@/components/controls/OpenChatButton.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "UserTruck",
  components: { OpenChatButton, UserStatus, TruckStatus },
  mixins: [DateTimeMixin],
  props: {
    truck: {
      type: Object,
      required: true,
    },
    user: Object,
    excludeUser: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
    showFirstDriver() {
      return this.truck.driver?.id && (!this.excludeUser || this.truck.driver?.id !== this.user?.id);
    },
    showSecondDriver() {
      return this.truck.driver2?.id && (!this.excludeUser || this.truck.driver2?.id !== this.user?.id);
    },
  },
};
</script>

<template>
  <div class="truck-block">
    <div class="d-flex justify-space-between align-center mb-2">
      <div class="mr-2 overflow-hidden">
        <router-link
          :to="{ name: 'truck-view', params: { id: truck.id } }"
          class="text-decoration-none d-flex align-center"
          target="_blank"
        >
          <div class="d-flex align-center justify-center mr-1 icon-block--medium">
            <v-icon class="text-primary" size="16">mdi-truck-outline</v-icon>
          </div>
          <span class="text-primary font-weight-medium">{{ $t("trucks.truck") }} </span>
          <span
            class="ml-1 text-primary font-weight-medium text-uppercase overflow-hidden white-space-nowrap text-overflow-ellipsis"
          >
            {{ truck.number }}
          </span>
        </router-link>
      </div>
      <truck-status :truck="truck" />
    </div>
    <div class="d-flex align-center mb-2">
      <div class="d-flex align-center justify-center icon-block--medium mr-1">
        <v-icon class="text-grey-darken-2" size="16">mdi-map-marker-radius-outline</v-icon>
      </div>
      <v-tooltip location="top start">
        <template #activator="{ props }">
          <span class="text-grey-darken-3 caption" v-bind="props">
            {{ truck.availableLocation?.fullAddress || "Location unknown" }}
          </span>
        </template>
        Available location
      </v-tooltip>
    </div>
    <div v-if="truck.availableDate" class="d-flex align-center mb-2">
      <div class="d-flex align-center justify-center icon-block--medium mr-1">
        <v-icon class="text-grey-darken-2" size="16">mdi-calendar-start-outline</v-icon>
      </div>
      <v-tooltip location="top start">
        <template #activator="{ props }">
          <span class="text-grey-darken-3 caption" v-bind="props">
            {{ dateTZ(truck.availableDate, dispatcher.timezone) }}
          </span>
        </template>
        Available time
      </v-tooltip>
    </div>
    <div v-if="showFirstDriver" class="d-flex align-center justify-space-between truck-driver__info">
      <div class="d-flex align-center overflow-hidden mr-3">
        <div class="d-flex align-center overflow-hidden mr-2">
          <div
            class="bg-white text-indigo-lighten-2 font-weight-600 status-item text-uppercase rounded-circle mr-2 d-flex align-center justify-center font-size-11"
          >
            DR
          </div>
          <v-tooltip location="top start">
            <template #activator="{ props }">
              <router-link
                :to="{ name: 'user-view', params: { id: truck.driver.id } }"
                class="text-primary text-caption font-weight-medium driver-switch-name text-decoration-none"
                target="_blank"
                v-bind="props"
              >
                {{ truck.driver.name }}
              </router-link>
            </template>
            First driver
          </v-tooltip>
        </div>
        <user-status :status="truck.driver.driverStatus" />
      </div>
      <open-chat-button :user="truck.driver"></open-chat-button>
    </div>
    <div v-if="showSecondDriver" class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <div class="d-flex align-center overflow-hidden mr-2">
          <div class="d-flex align-center justify-center icon-block--medium mr-1">
            <v-icon class="text-grey-darken-2" size="16">mdi-account-switch-outline</v-icon>
          </div>
          <v-tooltip location="top start">
            <template #activator="{ props }">
              <router-link
                :to="{ name: 'user-view', params: { id: truck.driver2.id } }"
                class="text-grey-darken-1 caption driver-switch-name text-decoration-none"
                target="_blank"
                v-bind="props"
              >
                {{ truck.driver2.name }}
              </router-link>
            </template>
            Second driver
          </v-tooltip>
        </div>
        <user-status :status="truck.driver2.driverStatus" />
      </div>
      <open-chat-button :user="truck.driver2"></open-chat-button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
