<script>
import { OrdersMixin } from "@/views/orders/orders.mixin";
import RouteStopStatusLabel from "../../components/RouteStopStatus.vue";
import EditStopDialog from "@/views/trips/edit/dialogs/EditStopDialog.vue";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";
import { OrderStopType } from "@/data/order";
import AlertMessage from "@/components/AlertMessage.vue";

export default {
  name: "RouteStopBlock",
  components: {
    AlertMessage,
    FacilityInfo,
    EditStopDialog,
    RouteStopStatusLabel,
  },
  mixins: [OrdersMixin],
  props: {
    routeStop: {
      type: Object,
      required: true,
    },
    route: {
      type: Object,
    },
    allowEditUsage: {
      type: Boolean,
      default: false,
    },
    orderStops: {
      type: Array,
      required: true,
    },
    orderFreights: {
      type: Array,
      required: true,
    },
    disabled: Boolean,
  },
  emits: ["delete", "changed:freights", "changed:stop"],
  data() {
    return {
      editStopDialog: false,
    };
  },
  computed: {
    stop: {
      get() {
        return this.routeStop;
      },
      set(value) {
        this.$emit("changed:stop", value);
      },
    },
    routeStopFreights() {
      let number = 1;
      return this.stop.freights
        .map((f) => this.route.freights.find((rf) => rf.id === f))
        .filter((f) => f !== undefined && f.id)
        .map((f) => {
          const orderFreight = this.orderFreights.find((of) => of.id === f.freightId);
          return {
            ...f,
            number: number,
            name: this.getFreightName(f, number++, "", false, true),
            orderFreight,
            isDeleted: orderFreight.isDeleted,
          };
        })
        .sort((a, b) => parseInt(a.number) - parseInt(b.number));
    },
    unusedRouteFreights() {
      if (!this.isPickup) {
        return [];
      }
      return this.route.freights.filter(
        (f) =>
          !this.route.routeStops
            .filter((rs) => rs.type === OrderStopType.delivery)
            .flatMap((s) => s.freights)
            .includes(f.id),
      );
    },
    unusedRouteStopFreights() {
      return this.unusedRouteFreights.filter((f) => this.stop.freights.includes(f.id));
    },
    deletedRouteStopFreights() {
      return this.routeStopFreights.filter((f) => f.orderFreight?.isDeleted);
    },
    isPickup() {
      return this.stop.type === OrderStopType.pickup;
    },
    isDeleted() {
      return this.orderStop.isDeleted;
    },
    isUpdated() {
      return (
        (!this.stop.isReload && this.unusedRouteStopFreights.length > 0) || this.deletedRouteStopFreights.length > 0
      );
    },
    orderStop() {
      return this.orderStops.find((os) => os.id === this.stop.orderStopId);
    },
  },
  async created() {
    if (this.disabled && this.stop.oldFacilityId && !this.stop.oldFacility) {
      const response = await this.$api.company.findFacilityById(this.stop.oldFacilityId);
      if (response.success) {
        this.stop.oldFacility = response.data;
      }
    }
  },
  methods: {
    usageChanged() {
      if (this.stop.use && !this.stop.dateTimeTo) {
        this.showEditDialog();
      }
    },
    showEditDialog() {
      this.editStopDialog = true;
    },
    onStopChanged(stop, freights) {
      this.$emit("changed:stop", stop);
      this.$emit("changed:freights", freights);
    },
  },
};
</script>

<template>
  <div :class="{ 'reload-stop': stop.isReload }" class="trip-stops__block">
    <div v-if="!stop.use" class="trip-stops__unused pa-4 rounded">
      <div class="trip-stops__block-header">
        <div class="d-flex align-center justify-space-between mb-2">
          <div class="d-flex align-center flex-wrap">
            <v-icon v-if="stop.type === STOP_PICK_UP" :color="isDeleted ? 'grey' : 'green'" class="mr-2" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon
              v-if="stop.type === STOP_DELIVERY"
              :color="isDeleted ? 'grey' : 'red accent-2'"
              class="mr-2"
              size="20"
            >
              mdi-map-marker-radius-outline
            </v-icon>
            <span
              :class="isDeleted ? 'text-grey' : 'text-grey-darken-1'"
              class="font-weight-600 mr-3 font-size-12"
              data-qa="stop-type"
            >
              {{ stop.name }}
            </span>
            <router-link
              v-if="stop.order"
              :to="{ name: 'order-view', params: { id: stop.order.orderId || stop.order.id } }"
              class="font-size-12 font-weight-medium text-primary text-decoration-none"
              target="_blank"
            >
              Order #{{ stop.order.number }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center align-center trip-stops__unused__inner">
        <v-btn :disabled="disabled" class="text-primary text-uppercase" variant="flat" @click="editStopDialog = true">
          <v-icon class="mr-1" icon="mdi-plus"></v-icon>
          Add
          <template v-if="isPickup">pick up</template>
          <template v-else>delivery</template>
        </v-btn>
      </div>
    </div>
    <div
      v-else
      :class="{ 'bg-red-lighten-5': disabled, 'bg-grey-lighten-4': !disabled, 'border-warning': isUpdated }"
      class="trip-stops__block-inner pa-4 rounded"
    >
      <div class="mb-6 trip-stops__block-header">
        <div class="d-flex align-center justify-space-between mb-2">
          <div class="d-flex align-center flex-wrap">
            <v-icon v-if="stop.type === STOP_PICK_UP" :color="isDeleted ? 'grey' : 'green'" class="mr-2" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon
              v-if="stop.type === STOP_DELIVERY"
              :color="isDeleted ? 'grey' : 'red accent-2'"
              class="mr-2"
              size="20"
            >
              mdi-map-marker-radius-outline
            </v-icon>
            <span
              :class="isDeleted ? 'text-grey' : 'text-grey-darken-1'"
              class="font-weight-600 mr-3 font-size-12"
              data-qa="stop-type"
            >
              {{ stop.isReload ? stop.name : orderStop?.name }}
            </span>
            <router-link
              v-if="stop.order && !isDeleted"
              :to="{ name: 'order-view', params: { id: stop.order.orderId || stop.order.id } }"
              class="font-size-12 font-weight-medium text-primary text-decoration-none"
              target="_blank"
            >
              Order #{{ stop.order.number }}
            </router-link>
            <span v-if="isDeleted" class="text-grey font-size-12 font-weight-medium">
              Order #{{ stop.order.number }}
            </span>
          </div>
          <div>
            <v-btn
              v-if="stop.type === STOP_RELOAD"
              class="text-red text--accent-2 bg-transparent"
              icon="mdi-delete-outline"
              size="x-small"
              variant="flat"
              @click="$emit('delete')"
            />
            <v-btn
              v-if="!disabled"
              :disabled="isDeleted"
              color="primary"
              icon="mdi-pencil-outline"
              size="x-small"
              variant="text"
              @click="editStopDialog = true"
            />
          </div>
        </div>
        <div>
          <route-stop-status-label :stop="stop"></route-stop-status-label>
        </div>
      </div>

      <div class="mb-6 trip-stops__block-body">
        <facility-info
          v-if="stop.facility"
          :disabled="isDeleted"
          :facility="stop.facility"
          :updated="!!stop.oldFacilityId && !disabled"
          class="mb-5"
        ></facility-info>
        <div
          v-if="stop.timeType && !stop.isReload"
          :class="isDeleted ? 'text-grey' : 'text-grey-darken-1'"
          class="d-flex align-center"
        >
          <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
          <span class="font-weight-medium" data-qa="stop-time">
            {{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}
          </span>
        </div>
      </div>
      <div class="trip-stops__block-footer">
        <div class="d-flex">
          <div class="text-grey-darken-1 mr-2">Freight:</div>
          <div v-if="!routeStopFreights.length" class="text-grey-darken-1 font-weight-500">Not added</div>
          <div
            :class="{ 'text-error': disabled, 'text-grey': isDeleted, 'text-grey-darken-2': !disabled && !isDeleted }"
            class="font-weight-medium"
            data-qa="freight-size"
          >
            <div
              v-for="freight in routeStopFreights"
              :key="freight.id"
              :class="{ 'freight--deleted': freight.orderFreight?.isDeleted }"
            >
              {{ freight.name }}
            </div>
          </div>
        </div>
        <alert-message
          v-for="deletedFreight in deletedRouteStopFreights"
          :key="deletedFreight.id"
          bg-color="indigo-lighten-5"
          border-color="none"
          class="mt-3"
          color="indigo"
          icon="mdi-information-outline"
          icon-color="indigo"
        >
          Freight #{{ deletedFreight.number }} will be removed after re-dispatch confirmation
        </alert-message>

        <alert-message v-for="freight in unusedRouteStopFreights" :key="freight.id" class="mt-3">
          Freight #{{ freight.number }} is not selected at any DEL
        </alert-message>

        <alert-message v-if="isPickup && routeStopFreights.length === 0" class="mt-3">
          Route stop must have at least one route freight
        </alert-message>

        <alert-message
          v-if="isDeleted"
          bg-color="indigo-lighten-5"
          border-color="none"
          class="mt-3"
          color="indigo"
          icon="mdi-information-outline"
          icon-color="indigo"
        >
          Stop will be removed from trip after re-dispatch confirmation
        </alert-message>
      </div>
    </div>
    <edit-stop-dialog
      v-model="editStopDialog"
      :allow-edit-usage="allowEditUsage"
      :order-freights="orderFreights"
      :order-stops="orderStops"
      :route-freights="route.freights"
      :stop="stop"
      :unused-route-freights="unusedRouteFreights"
      @changed="onStopChanged"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.reload-stop {
  margin-bottom: 8px;

  .trip-stops__unused {
    border: none;
  }
}

.trip-stops__unused {
  border: 1px solid $grey-light;
  height: 100%;

  .trip-stops__unused__inner {
    height: calc(100% - 20px);
  }
}

.trip-stops__block-inner {
  height: 100%;
}

.badge {
  margin-top: -16px;
  margin-right: -16px;
}

.freight--deleted {
  text-decoration-line: line-through;
  text-decoration-color: $grey-lighter-contrast;
  color: $grey-lighter-contrast !important;

  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $grey-lighter-contrast;
  }
}
</style>
