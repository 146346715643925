import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { OrderFreightTypeMap, OrderStopTimeTypeMap, OrderStopType } from "@/data/order";
import { FacilityTypeMap } from "@/data/facility";
import { defineComponent, getCurrentInstance } from "vue";
import type { OrderDto, OrderFreightDto, OrderStopDto } from "@/api/orders/dto/order.dto";
import type { RouteFreightDto } from "@/api/trips/dto/trip.dto";

export const OrdersMixin = defineComponent({
  mixins: [DateTimeMixin],
  data() {
    return {
      STOP_DELIVERY: OrderStopType.delivery,
      STOP_PICK_UP: OrderStopType.pickup,
      STOP_RELOAD: OrderStopType.reload,
      freightTypePallets: "pl",
      freightTypePieces: "pc",
      timeTypesMap: {
        ap: "Appointment",
        as: "ASAP",
        d: "Direct",
        f: "FCFS",
      } as { [key: string]: string },
      certificates: ["Hazmat", "Tanker", "TSA", "TWIC"],
    };
  },
  computed: {
    facilityTypesMap(): { [key: string]: string } {
      const map: { [key: string]: string } = {};
      for (const facilityType of FacilityTypeMap) {
        map[facilityType.id] = facilityType.name;
      }
      return map;
    },
  },
  methods: {
    getOrderRoute(order: OrderDto): string {
      let firstPickup = null,
        lastDelivery = null;
      for (const stop of order.orderStops) {
        if (stop.type === this.STOP_PICK_UP && firstPickup === null) {
          firstPickup = stop;
        }
        if (stop.type === this.STOP_DELIVERY) {
          lastDelivery = stop;
        }
      }

      return `${firstPickup?.facility?.address} → ${lastDelivery?.facility?.address}`;
    },
    getStopDistance(stops: OrderStopDto[], distances: { [key: string]: number }, orderIndex: number): string {
      const distanceKey = `${stops[orderIndex].id}-${stops[orderIndex + 1].id}`;
      const distanceInMiles = distances[distanceKey] || 0;
      return this.$filters.miles(distanceInMiles);
    },
    getStopTime(stop: OrderStopDto): string {
      const times = [];
      if (stop.timeTo) {
        times.push(this.getTimeInterval(stop.timeFrom!, stop.timeTo, stop.timezone));
      }
      if (stop.time2To) {
        times.push(this.getTimeInterval(stop.time2From!, stop.time2To, stop.timezone));
      }
      return times.join(", ");
    },
    getTimeType(id: string) {
      return OrderStopTimeTypeMap.find((t) => t.id === id)?.name;
    },
    getTimeInterval(from: string | undefined, to: string | undefined, timezone: string): string {
      const tzShort = DateTimeMixin.methods!.getTZNames(timezone);
      const intervals = [];

      if (from) intervals.push(this.getDateToDisplay(from, timezone));
      if (to) intervals.push(this.getDateToDisplay(to, timezone));
      if (intervals.length === 0) return "";
      if (intervals.length === 2) {
        if (intervals[0].date === intervals[1].date) {
          return intervals[0].date + " " + intervals[0].time + " - " + intervals[1].time + " " + tzShort;
        }
      }

      return intervals.map((d) => d.date + " " + d.time).join(" - ") + " " + tzShort;
    },
    getDateToDisplay(date: string, timezone: string) {
      const app = getCurrentInstance();
      const dayjs = app?.appContext.config.globalProperties.$dayjs;
      const dateTz = dayjs(date).tz(timezone);
      return {
        date: dateTz.format("MM/DD/YYYY"),
        time: dateTz.format("HH:mm"),
      };
    },
    getTimeTypes(type: OrderStopType) {
      if (type === OrderStopType.pickup) {
        return Object.keys(this.timeTypesMap)
          .filter((t) => t !== "d")
          .map((k) => ({
            id: k,
            name: this.timeTypesMap[k] as string,
          }));
      } else {
        return Object.keys(this.timeTypesMap)
          .filter((t) => t !== "as")
          .map((k) => ({
            id: k,
            name: this.timeTypesMap[k],
          }));
      }
    },
    getFreightName(
      freight: RouteFreightDto,
      number = null,
      firstName = "Freight",
      withStackable = true,
      asPlanned = false,
    ) {
      const nameParts = [firstName, number ? "#" + number + ":" : ""];
      if (asPlanned) {
        if (freight.plannedQuantity) {
          nameParts.push(freight.plannedQuantity.toString());
          nameParts.push(freight.type === "pl" ? "pallets" : "pieces");
        }
        if (freight.plannedWeight) {
          nameParts.push(freight.plannedWeight.toString());
          nameParts.push("lbs");
        }
      } else {
        if (freight.quantity) {
          nameParts.push(freight.quantity.toString());
          nameParts.push(freight.type === "pl" ? "pallets" : "pieces");
        }
        if (freight.weight) {
          nameParts.push(freight.weight.toString());
          nameParts.push("lbs");
        }
      }
      if (freight.length && freight.width && freight.height) {
        nameParts.push(freight.length.toString());
        nameParts.push("x");
        nameParts.push(freight.width.toString());
        nameParts.push("x");
        nameParts.push(freight.height.toString());
      }
      if (!freight.stackable && withStackable) {
        nameParts.push("Not stackable");
      }
      return nameParts.join(" ");
    },
    getFreightTypeName(freight: OrderFreightDto) {
      return OrderFreightTypeMap.find((ft) => ft.id === freight.type)?.name;
    },
  },
});
