<script>
import BaseView from "@/views/BaseView.vue";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import Messages from "@/views/chats/Messages.vue";
import ChatInfo from "@/views/chats/chat-info/ChatInfo.vue";
import ThreadRow from "@/views/chats/ThreadRow.vue";
import SelectDispatchers from "@/components/SelectDispatchers.vue";
import SidebarLayout from "@/components/layout/SidebarLayout.vue";
import { ChatTab } from "@/enums/chat";
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { useAppStore } from "@/store/app.store";
import { useChatStore } from "@/store/chat.store";

const defaultThread = { name: "" };
const colleaguesGroup = "c";

export default defineComponent({
  name: "ChatPage",
  components: {
    SidebarLayout,
    SelectDispatchers,
    ChatInfo,
    Messages,
    ThreadRow,
  },
  mixins: [BaseView, ChatMixin],
  data() {
    return {
      perm: "chats",
      groups: [ChatTab.New],

      selectedDispatchers: null,
      showChatInfoPanel: false,
      showChatInfoDialog: false,

      selected: null,
      selectedThread: defaultThread,

      search: "",

      newMessageInfo: true,

      activeChatInfo: false,
      activeMessagesBlock: false,
    };
  },
  computed: {
    ChatTab() {
      return ChatTab;
    },
    ...mapState(useAppStore, ["mobile"]),
    ...mapState(useChatStore, ["threads", "threadsCount", "threadsMessages", "activeTabs"]),
    colleaguesThreadsCount() {
      if (this.selectedDispatchers && this.selectedDispatchers.length > 0) {
        let threadsCount = 0;
        for (const thread of this.threads) {
          if (thread.group === colleaguesGroup && this.selectedDispatchers.indexOf(thread.dispatcherId) > -1) {
            threadsCount++;
          }
        }
        return threadsCount;
      }
      return this.threadsCount.colleagues;
    },
    colleaguesThreadWithMessages() {
      if (this.selectedDispatchers && this.selectedDispatchers.length > 0) {
        let threadsWithMessages = 0;
        for (const thread of this.threads) {
          if (
            thread.group === colleaguesGroup &&
            thread.newMessagesCount > 0 &&
            this.selectedDispatchers.indexOf(thread.dispatcherId) > -1
          ) {
            threadsWithMessages++;
          }
        }
        return threadsWithMessages;
      }
      return this.threadsMessages.colleagues;
    },
    threadsList() {
      // Google search algorithm
      const search = (this.search || "").toLowerCase().trim(),
        nonEmptySearch = search.length > 0,
        searchByColleagues = this.groups.includes(ChatTab.Colleagues);
      const phoneSearch = search.replace(/[^a-zA-Z0-9]/g, "");

      return this.threads
        .filter((thread) => {
          if (nonEmptySearch) {
            return (
              thread.name?.toLowerCase().indexOf(search) > -1 ||
              thread.user.email?.toLowerCase().indexOf(search) > -1 ||
              thread.user.phone?.toLowerCase().indexOf(phoneSearch) > -1 ||
              thread.user.mobilePhone2?.toLowerCase().indexOf(phoneSearch) > -1 ||
              thread.user.driverTruck?.number.toLowerCase().indexOf(search) > -1
            );
          } else {
            if (thread.group === ChatTab.Colleagues && searchByColleagues) {
              if (this.selectedDispatchers && this.selectedDispatchers.length > 0) {
                return this.selectedDispatchers.indexOf(thread.dispatcherId) > -1;
              }
              return true;
            }
            return this.groups.includes(thread.group);
          }
        })
        .sort((a, b) => {
          // Define a function to assign a category based on thread properties.
          const getCategory = (thread) => {
            if (thread.group === ChatTab.Archive) {
              return 3; // Always last, even if newMessagesCount > 0.
            }
            return thread.newMessagesCount > 0 ? 1 : 2;
          };

          const catA = getCategory(a);
          const catB = getCategory(b);

          // Sort by category (lower category value comes first).
          return catA - catB;
        });
    },
  },
  watch: {
    selectedDispatchers(value) {
      this.setSearchParams("chat", { dispatchers: value });
    },
    groups() {
      this.saveTabsState(this.groups);
    },
    async threads() {
      if (this.openThreadById) {
        for (let n in this.threads) {
          if (this.threads[n].id === this.$route.params.id) {
            this.selected = n;
            await this.setThread(this.threads[n], n);

            this.openThreadById = false;
          }
        }
      } else {
        for (const thread of this.threads) {
          // update data only for selected thread
          if (thread.id === this.selectedThread.id) {
            this.selectedThread = thread;
          }
        }
      }
    },
  },
  async mounted() {
    this.setTitle(this.$t("chats.chats"));
    this.selectedDispatchers = this.getSearchParams("chat")?.dispatchers;

    // open thread by id param from URL
    this.openThreadById = !!this.$route.params.id;
    this.groups = this.activeTabs.length > 0 ? this.activeTabs : [ChatTab.New];
  },
  methods: {
    ...mapActions(useChatStore, ["saveTabsState"]),
    groupsChanged() {},
    getAvatar(thread) {
      if (thread.avatar) return thread.avatar;
      if (!thread.type) return null;
    },
    async setThread(thread) {
      if (!this.selectedThread || thread.id !== this.selectedThread.id) {
        const resp = await this.$api.users.findUserById(thread.user.id);
        if (resp.success) {
          thread.user = { ...thread.user, ...resp.data };
        }

        this.selectedThread = thread;
        await this.$ws.sendCommand("messages", {
          threadId: this.selectedThread.id,
          limit: 25,
        });

        this.activeMessagesBlock = true;
      }
    },
    clearThread() {
      this.selectedThread = defaultThread;
    },
  },
});
</script>

<template>
  <v-container :fluid="true" class="chat-app pt-8 pb-0 pl-6 pr-6">
    <sidebar-layout class="height-100">
      <div class="chats height-100 d-flex flex-column">
        <div class="chats__left-top">
          <v-text-field
            v-model="search"
            :clearable="true"
            class="search-block mb-6"
            color="primary"
            density="compact"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            @click:clear="search = ''"
          />

          <v-btn-toggle
            v-show="!search"
            v-model="groups"
            :multiple="true"
            border="2"
            class="mb-6 width-100 chats__tabs"
            color="primary"
            density="compact"
            @change="groupsChanged"
          >
            <v-btn :value="ChatTab.New" class="flex-1 mr-2 text-body-2 text-grey-darken-2 pr-2 pl-2 position-relative">
              <v-badge v-if="threadsMessages.new" :content="threadsMessages.new" color="green" />
              <span>
                {{ $t("chats.new") }}
                <span v-if="threadsCount.new > 0" class="chats-counter caption text-grey-darken-2 ml-1">
                  {{ threadsCount.new }}
                </span>
              </span>
            </v-btn>

            <v-btn :value="ChatTab.My" class="flex-1 mr-2 text-grey-darken-2 text-body-2 pr-2 pl-2">
              <v-badge v-if="threadsMessages.my" :content="threadsMessages.my" color="green" />
              {{ $t("chats.my") }}
              <span v-if="threadsCount.my > 0" class="chats-counter caption text-grey-darken-2 ml-1">
                {{ threadsCount.my }}
              </span>
            </v-btn>

            <v-btn :value="ChatTab.Colleagues" class="flex-1 mr-2 text-grey-darken-2 text-body-2 pr-2 pl-2">
              <v-badge v-if="colleaguesThreadWithMessages" :content="colleaguesThreadWithMessages" color="green" />
              {{ $t("chats.colleagues") }}
              <span v-if="colleaguesThreadsCount > 0" class="chats-counter caption text-grey-darken-2 ml-1">
                {{ colleaguesThreadsCount }}
              </span>
            </v-btn>

            <v-btn :value="ChatTab.Archive" class="flex-1 text-grey-darken-2 text-body-2 pr-2 pl-2">
              {{ $t("chats.archive") }}
            </v-btn>
          </v-btn-toggle>

          <div v-show="!search && groups.includes(ChatTab.Colleagues)" class="mb-5">
            <select-dispatchers v-model="selectedDispatchers" :display-selected="4" :multiple="true" />
          </div>
        </div>
        <div class="chats__left-bottom flex-1 overflow-hidden d-flex flex-column">
          <v-virtual-scroll :items="threadsList" class="overflow-y-auto scroll-style flex-grow-1">
            <template #default="{ item }">
              <thread-row
                :key="item.id"
                :class="{ 'v-list-item--active': selectedThread.id === item.id }"
                :data-id="item.id"
                :highlight-border="item.group !== ChatTab.Archive"
                :thread="item"
                @click="setThread(item)"
              />
            </template>
          </v-virtual-scroll>
          <div v-if="threadsList.length === 0" class="text-center caption">
            {{ $t("chats.no-chats") }}
          </div>
        </div>
      </div>

      <div :class="{ active: selectedThread.name !== '' }" class="messages-area height-100">
        <messages
          v-if="selectedThread.name !== ''"
          :image="getAvatar(selectedThread)"
          :thread="selectedThread"
          @hide="clearThread"
        />
      </div>

      <template #sidebar>
        <chat-info :thread="selectedThread" />
      </template>
    </sidebar-layout>
  </v-container>
</template>

<style lang="scss">
.chats__tabs {
  .v-badge__wrapper {
    position: inherit !important;
  }

  .v-btn {
    border-radius: inherit !important;
  }
}
</style>

<style lang="scss" scoped>
.chats__tabs {
  background: transparent !important;
  overflow: visible;

  button {
    border: 1px solid #f5f5f5;
  }

  .chats-counter {
    opacity: 0.45;
  }

  .v-btn--active {
    background: #556ee6;
    color: #fff !important;
    border: 1px solid #556ee6;

    .chats-counter {
      color: #fff !important;
      opacity: 1;
    }
  }
}

.v-input--is-focused {
  fieldset {
    border: 0 !important;
  }
}

.chat-app {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 65px);
  @media (max-width: 991px) {
    padding-top: 30px !important;
  }
  @media (max-width: 767px) {
    & > .d-flex {
      position: initial;
    }
  }

  & > .v-card {
    background: transparent !important;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .chats {
    min-width: 200px;
    width: 4000px;
    max-width: 340px;
    flex: 0 0 340px;
    background: #f8f8fb;
    padding-right: 16px;
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
    }

    .v-list {
      // height: calc(100vh - 325px);
      background: transparent;
      // box-shadow: 0px 8px 20px -7px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 4px 4px;
      padding: 0;

      .v-list-item--active {
        box-shadow: 0 8px 20px -7px rgba(0, 0, 0, 0.1) !important;
      }
    }

    .thread-closed {
      font-weight: normal;
      background: #e1e1e1;
    }

    .v-badge {
      .v-badge__badge {
        z-index: 9 !important;
      }
    }
  }

  .messages-area {
    overflow-y: auto;
    width: 100%;
    // border-left: 1px solid rgba(0, 0, 0, 0.2);
    background: #f8f8fb;
    // box-shadow: 0 8px 20px -7px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    flex: 1;
    @media (max-width: 767px) {
      & {
        transform: translateX(-100%);
      }
      &.active {
        position: absolute;
        transform: translateX(0);
        left: 0;
        top: 0;
        width: 100%;
        z-index: 99;
      }
    }
  }

  .info-panel {
    max-width: 405px;
    flex: 0 0 405px;
    // border-left: 1px solid rgba(0, 0, 0, 0.2);
    @media (max-width: 1024px) {
      & {
        transform: translateX(-100%);
        transition: 0.7s;
      }
      &.active {
        position: absolute;
        right: 0;
        transform: translateX(0);
        transition: 0.7s;
        z-index: 99;
        width: calc(100% - 340px);
        height: 100%;
        border-radius: 4px;
        max-width: inherit;
      }
    }
    @media (max-width: 767px) {
      &.active {
        width: calc(100% - 48px);
        right: auto;
        left: 24px;
        height: calc(100% - 52px);
        margin: 0 !important;
      }
    }
  }
}

.chats__left-bottom {
  position: relative;
  box-shadow: 0 8px 20px -7px rgba(0, 0, 0, 0.1);
}
</style>
