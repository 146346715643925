<script lang="ts" setup>
import { computed, inject, ref, watch } from "vue";
import OmniDialog from "@/components/OmniDialog.vue";
import type { OrderDto, OrderListItemDto } from "@/api/orders/dto/order.dto";
import type { API } from "@/api/api";

const api = inject<API>("api");

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
  }>(),
  {
    modelValue: false,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "change", value: OrderDto): void;
}>();

const orderId = ref<string | null>(null);
const orders = ref<OrderListItemDto[]>([]);
const search = ref("");
const isLoading = ref(false);

const addOrderDialog = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit("update:modelValue", value),
});

watch(search, (value) => {
  if (isLoading.value) return;
  fetchOrders(value);
});

async function attach() {
  if (!orderId.value) return;
  isLoading.value = true;
  const response = await api!.orders.findOrderById(orderId.value);
  if (response.success && response.data) {
    emit("change", response!.data!);
  }
  isLoading.value = false;
}

async function fetchOrders(term: string) {
  const params = { number: term, pageSize: 10 };

  isLoading.value = true;
  const response = await api!.orders.findOrders(params);
  if (response.success && response.data) {
    orders.value = response!.data!.items;
  } else {
    console.log("Error loading orders", response.error);
  }
  isLoading.value = false;
}
</script>

<template>
  <omni-dialog
    v-model="addOrderDialog"
    primary-action-label="Add"
    title="Add order"
    width="360"
    @primary-action:click="attach"
  >
    <div class="py-4">
      <v-autocomplete
        v-model="orderId"
        v-model:search="search"
        :items="orders"
        :loading="isLoading"
        density="compact"
        hide-details
        item-title="number"
        item-value="id"
        label="Order number"
        variant="outlined"
      />
    </div>
  </omni-dialog>
</template>
