import { BaseApi } from "@/api/base";
import type { RouteStopDto, TripDto, TripRouteDto } from "@/api/trips/dto/trip.dto";
import type { Response } from "@/api/client";
import type { FileDto } from "@/api/files/dto/file.dto";
import type { CheckCallResponse } from "@/api/trips/dto/check-call";
import type { TripUpdateResponse } from "@/api/trips/dto/update";

export class TripsApi extends BaseApi {
  getTripById(tripId: number): Promise<Response<TripDto>> {
    return this.client.get("/trips/" + tripId);
  }

  upsertTrip(trip: any): Promise<Response<TripDto>> {
    return this.client.save("/trips", trip);
  }

  confirmRouteDispatch(tripId: string, routeId: string, data: any): Promise<Response<TripRouteDto>> {
    return this.client.post("/trips/" + tripId + "/routes/" + routeId + "/confirm-dispatch", data);
  }

  confirmRouteRedispatch(tripId: string, routeId: string): Promise<Response<TripRouteDto>> {
    return this.client.post("/trips/" + tripId + "/routes/" + routeId + "/confirm-redispatch", {});
  }

  checkInRouteStop(tripId: string, routeStopId: string, data: any): Promise<Response<RouteStopDto>> {
    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/check-in", data);
  }

  getRouteStop(tripId: string, routeStopId: string): Promise<Response<RouteStopDto>> {
    return this.client.get("/trips/" + tripId + "/route-stops/" + routeStopId);
  }

  loadRouteStop(tripId: string, routeStopId: string, data: any): Promise<Response<RouteStopDto>> {
    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/load", data);
  }

  uploadRouteStopFile(
    tripId: string,
    routeStopId: string,
    fileType: string,
    file: File,
    bol?: string,
  ): Promise<Response<FileDto>> {
    const data = new FormData();
    data.append("file", file);
    if (bol) {
      data.append("bol", bol);
    }

    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/files/" + fileType, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  unloadRouteStop(tripId: string, routeStopId: string, data: any): Promise<Response<RouteStopDto>> {
    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/unload", data);
  }

  checkOutRouteStop(tripId: string, routeStopId: string, data: any): Promise<Response<RouteStopDto>> {
    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/good-to-go", data);
  }

  cancelDispatchConfirmation(tripId: string, routeId: string): Promise<Response<void>> {
    return this.client.delete("/trips/" + tripId + "/routes/" + routeId + "/confirm-dispatch");
  }

  cancelCheckIn(tripId: string, stopId: string): Promise<Response<void>> {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/check-in");
  }

  cancelLoad(tripId: string, stopId: string): Promise<Response<void>> {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/load");
  }

  cancelLoadVerification(tripId: string, stopId: string): Promise<Response<void>> {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/load-verify");
  }

  cancelUnload(tripId: string, stopId: string): Promise<Response<void>> {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/unload");
  }

  cancelUnloadVerification(tripId: string, stopId: string): Promise<Response<void>> {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/unload-verify");
  }

  cancelGoodToGo(tripId: string, stopId: string): Promise<Response<void>> {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/good-to-go");
  }

  cancelTravelOrder(tripId: string, travelOrderId: string, data: any): Promise<Response<TripRouteDto>> {
    return this.client.post("/trips/" + tripId + "/travel-orders/" + travelOrderId + "/cancel", data);
  }

  getTripFiles(tripId: string): Promise<Response<FileDto[]>> {
    return this.client.get("/trips/" + tripId + "/files");
  }

  getCheckCalls(tripId: string): Promise<Response<CheckCallResponse>> {
    return this.client.get("/trips/" + tripId + "/check-calls");
  }

  getUpdates(tripId: string): Promise<Response<TripUpdateResponse>> {
    return this.client.get("/trips/" + tripId + "/updates");
  }
}
