<script lang="ts" setup>
import { watch } from "vue";
import type { TripDto } from "@/api/trips/dto/trip.dto";
import { useRoute, useRouter } from "vue-router";

const location = useRoute();
const router = useRouter();

const props = defineProps<{
  trip: TripDto;
}>();

defineEmits(["change"]);

watch(
  () => props.trip,
  (value) => {
    if (!location.params.routeId && value) {
      router.push({ name: "trip-check-calls-route", params: { id: props.trip.id, routeId: props.trip.routes[0]?.id } });
    }
  },
);
</script>

<template>
  <div class="my-4">
    <v-btn
      v-for="route in props.trip?.routes"
      :key="route.id"
      :to="{ name: 'trip-check-calls-route', params: { id: trip.id, routeId: route.id } }"
      active-color="primary"
      variant="flat"
    >
      Truck #{{ route.$truck?.number }}
    </v-btn>
  </div>
  <router-view v-slot="{ Component }">
    <component :is="Component" :trip="trip" />
  </router-view>
</template>

<style lang="scss" scoped></style>
