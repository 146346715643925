import { createRouter, createWebHistory } from "vue-router";

import Login from "@/views/account/Login.vue";
import ChangePassword from "@/views/account/ChangePassword.vue";
import SetPassword from "@/views/account/SetPassword.vue";
import ResetPassword from "@/views/account/ResetPassword.vue";

import Dashboard from "@/views/Dashboard.vue";
import Chat from "@/views/chats/Chat.vue";
import Dispatchers from "@/views/dispatchers/Dispatchers.vue";

import Facilities from "@/views/companies/facilities/Facilities.vue";
import FacilitiesEdit from "@/views/companies/facilities/Edit.vue";

import Coordinators from "@/views/coordinators/Coordinators.vue";
import Owners from "@/views/owners/Owners.vue";
import Drivers from "@/views/drivers/Drivers.vue";
import UserEdit from "@/views/users/UserEdit.vue";
import UserView from "@/views/users/UserView.vue";

import Trucks from "@/views/trucks/Trucks.vue";
import TruckView from "@/views/trucks/TruckView.vue";
import TrucksSearch from "@/views/trucks/TrucksSearch.vue";
import TruckEdit from "@/views/trucks/edit/TruckEdit.vue";

import SettingsBase from "@/views/settings/Base.vue";
import Logs from "@/views/settings/Logs.vue";
import TrucksHistory from "@/views/trucks/history/TrucksHistory.vue";
import MapPosition from "@/views/misc/MapPosition.vue";
import Profile from "@/views/account/Profile.vue";
import Orders from "@/views/orders/Orders.vue";
import OrderEdit from "@/views/orders/OrderEdit.vue";
import TripEdit from "@/views/trips/edit/TripEdit.vue";
import CompanyProfile from "@/views/companies/CompanyProfile.vue";
import Carriers from "@/views/companies/carriers/Carriers.vue";
import CarriersEdit from "@/views/companies/carriers/Edit.vue";
import Customers from "@/views/companies/customers/Customers.vue";
import CustomerEdit from "@/views/companies/customers/Edit.vue";
import { UnauthorizedGuard } from "./guards/unauthorized.guard";
import { AuthorizedGuard } from "./guards/authorized.guard";
import NotFound from "@/views/misc/NotFound.vue";
import OrderView from "@/views/orders/OrderView.vue";
import TripView from "@/views/trips/view/TripView.vue";
import TripOverviewTab from "@/views/trips/view/TripOverviewTab.vue";
import TripCheckCallsTab from "@/views/trips/view/TripCheckCallsTab.vue";
import TripCheckCallsRouteTab from "@/views/trips/view/TripCheckCallsRouteTab.vue";
import TripBrokerUpdatesTab from "@/views/trips/view/TripBrokerUpdatesTab.vue";
import TripBrokerUpdatesOrderTab from "@/views/trips/view/TripBrokerUpdatesOrderTab.vue";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/dashboard", name: "index" },

    /// Security
    {
      path: "/login",
      component: Login,
      name: "login",
      meta: { layout: "blank" },
      beforeEnter: [UnauthorizedGuard],
    },
    {
      path: "/change-password",
      component: ChangePassword,
      name: "change-password",
      beforeEnter: [UnauthorizedGuard],
    },
    {
      path: "/reset-password",
      component: ResetPassword,
      name: "reset-password",
      meta: { layout: "blank" },
      beforeEnter: [UnauthorizedGuard],
    },
    {
      path: "/dispatchers/:id/set-password",
      component: SetPassword,
      name: "set-password",
      meta: { layout: "blank" },
      beforeEnter: [UnauthorizedGuard],
    },

    /// Dashboard
    {
      path: "/dashboard",
      component: Dashboard,
      name: "dashboard",
      meta: { section: "dashboard" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/account/profile",
      component: Profile,
      name: "account-profile",
      meta: { section: "account" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/chats",
      component: Chat,
      name: "chats",
      meta: { section: "chats" },
      beforeEnter: [AuthorizedGuard],
    },

    /// Orders
    {
      path: "/orders",
      component: Orders,
      name: "orders",
      meta: { section: "orders" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/orders/create",
      component: OrderEdit,
      name: "order-create",
      meta: { section: "orders" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/orders/:id/edit",
      component: OrderEdit,
      name: "order-edit",
      meta: { section: "orders" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/orders/:id",
      component: OrderView,
      name: "order-view",
      meta: { section: "orders" },
      beforeEnter: [AuthorizedGuard],
    },

    /// Trips
    {
      path: "/trips/:id",
      component: TripEdit,
      name: "trip-edit",
      meta: { section: "trips" },
    },
    {
      path: "/trips/:id/view",
      component: TripView,
      name: "trip-view-base",
      meta: { section: "trips" },
      children: [
        {
          path: "overview",
          name: "trip-view",
          component: TripOverviewTab,
          strict: false,
        },
        {
          path: "check-calls",
          name: "trip-check-calls",
          component: TripCheckCallsTab,
          children: [
            {
              path: "routes/:routeId",
              name: "trip-check-calls-route",
              component: TripCheckCallsRouteTab,
            },
          ],
        },
        {
          path: "broker-updates",
          name: "trip-broker-updates",
          component: TripBrokerUpdatesTab,
          children: [
            {
              path: "order/:orderId",
              name: "trip-broker-updates-order",
              component: TripBrokerUpdatesOrderTab,
            },
          ],
        },
      ],
    },

    /// Users
    {
      path: "/users/drivers",
      component: Drivers,
      name: "drivers",
      meta: { section: "drivers" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/users/owners",
      component: Owners,
      name: "owners",
      meta: { section: "owners" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/users/coordinators",
      component: Coordinators,
      name: "coordinators",
      meta: { section: "coordinators" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/users/create",
      component: UserEdit,
      name: "user-create",
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/users/:id(\\d+)/view",
      component: UserView,
      name: "user-view",
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/users/:id(\\d+)/edit",
      component: UserEdit,
      name: "user-edit",
      beforeEnter: [AuthorizedGuard],
    },

    /// Company
    {
      path: "/company/profile",
      component: CompanyProfile,
      name: "company-profile",
      meta: { section: "company" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/company/carriers",
      component: Carriers,
      name: "carriers",
      meta: { section: "carriers" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/company/carriers/:id/edit",
      component: CarriersEdit,
      name: "carriers-edit",
      meta: { section: "carriers" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/company/customers",
      component: Customers,
      name: "customers",
      meta: { section: "customers" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/company/customers/:id/edit",
      component: CustomerEdit,
      name: "customer-edit",
      meta: { section: "customers" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/company/facilities",
      component: Facilities,
      name: "facilities",
      meta: { section: "facilities" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/company/facilities/create",
      component: FacilitiesEdit,
      name: "facilities-create",
      meta: { section: "facilities" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/company/facilities/:id/edit",
      component: FacilitiesEdit,
      name: "facilities-edit",
      meta: { section: "facilities" },
      beforeEnter: [AuthorizedGuard],
    },

    // Trucks
    {
      path: "/fleets/trucks",
      component: Trucks,
      name: "trucks",
      meta: { section: "trucks" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/fleets/history",
      component: TrucksHistory,
      name: "trucks-history",
      meta: { section: "trucks" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/fleets/search",
      component: TrucksSearch,
      name: "trucks-search",
      meta: { section: "trucks" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/fleets/trucks/:id",
      component: TruckView,
      name: "truck-view",
      meta: { section: "trucks" },
      beforeEnter: [AuthorizedGuard],
    },
    {
      path: "/fleets/trucks/:id/edit",
      component: TruckEdit,
      name: "truck-edit",
      meta: { section: "trucks", action: "edit" },
      beforeEnter: [AuthorizedGuard],
    },

    /// Settings
    {
      path: "/settings",
      component: SettingsBase,
      beforeEnter: [AuthorizedGuard],
      children: [
        {
          path: "dispatchers",
          component: Dispatchers,
          name: "settings-dispatchers",
          meta: { section: "dispatchers" },
        },
        {
          path: "logs",
          component: Logs,
          name: "settings-logs",
          meta: { section: "logs" },
        },
      ],
    },

    /// Service
    {
      path: "/map/position",
      component: MapPosition,
      name: "map-position",
      beforeEnter: [AuthorizedGuard],
    },

    // Fallback
    { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
  ],
});
