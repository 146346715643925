<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import OmniTable from "@/components/OmniTable.vue";
import { type CheckCallDTO, CheckCallType, DeviationStatus } from "@/api/trips/dto/check-call";
import { FilterUtils } from "@/filters";
import { API } from "@/api/api";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { useAppStore } from "@/store/app.store";
import DeviationStatusLabel from "@/views/trips/components/DeviationStatusLabel.vue";
import type { RouteStopDto, TripDto } from "@/api/trips/dto/trip.dto";
import { RouteStopStatus } from "@/data/trip";
import { OrderStopType } from "@/data/order";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";
import type { OrderFacilityDto } from "@/api/orders/dto/order.dto";
import { OrdersMixin } from "@/views/orders/orders.mixin";
import AddCheckCallDialog from "@/views/trips/view/dialogs/AddCheckCallDialog.vue";
import CheckCallDateTime from "@/views/trips/view/components/CheckCallDateTime.vue";

const api: API = new API();
const filters = new FilterUtils();
const dateUtils = DateTimeMixin.methods!;
const orderUtils = OrdersMixin.methods!;

const route = useRoute();
const appStore = useAppStore();

const properties = defineProps<{
  trip: TripDto;
}>();

const checkCalls = ref<CheckCallDTO[]>([]);
const showAddCheckCallDialog = ref<boolean>(false);
const tableHeaders = [
  { title: "Type", key: "type", sortable: false },
  { title: "Location", key: "locationAddress", class: "text-grey", sortable: false },
  { title: "Miles from previous check call", key: "lastCallDistance", sortable: false },
  { title: "Miles from last stop", key: "lastStopDistance", sortable: false },
  { title: "Miles left to next stop", key: "nextStopDistance", sortable: false },
  { title: "Average speed, m/h", key: "avgSpeed", sortable: false },
  { title: "Date time", key: "dateTime", sortable: false },
  { title: "Created at, by", key: "createdAt", sortable: false },
];
const routeId = computed<number>(() => parseInt(route.params.routeId.toString()));
const tripId = computed(() => route.params.id.toString());
const nextStop = computed<RouteStopDto | undefined>(() =>
  properties.trip.routes
    ?.find((route) => route.id === routeId.value)
    ?.routeStops.find((stop: RouteStopDto) => stop.status === RouteStopStatus.inRoute),
);

onMounted(() => {
  loadCheckCalls();
});

function loadCheckCalls(): void {
  api.trips.getCheckCalls(tripId.value).then((response) => {
    if (response.success) {
      checkCalls.value = (response.data?.checkCalls || []).slice().reverse();
    }
  });
}

function getTypeLabel(type: CheckCallType): string {
  switch (type) {
    case CheckCallType.checkCall:
      return "Check call";
    case CheckCallType.dispatchConfirmed:
      return "Dispatch confirmed";
    case CheckCallType.checkedIn:
      return "Checked in";
    case CheckCallType.load:
      return "Loaded";
    case CheckCallType.unload:
      return "Unloaded";
    case CheckCallType.loadVerify:
    case CheckCallType.unloadVerify:
      return "Verified";
    case CheckCallType.goodToGo:
      return "Good to go";
    default:
      return "";
  }
}

function getDeviationStatusClass(deviationStatus: DeviationStatus): string {
  switch (deviationStatus) {
    case DeviationStatus.onTrack:
      return "text-success";
    case DeviationStatus.deviated:
      return "text-warning";
    case DeviationStatus.wrongWay:
      return "text-error";
    default:
      return "";
  }
}

function checkCallProps(row: Record<string, string> & { item: CheckCallDTO }): Record<string, string> {
  return {
    class: row.item.type === CheckCallType.dispatchConfirmed ? "bg-grey-lighten-3" : "",
  };
}

function isFacilityChanged(checkCall: CheckCallDTO): boolean {
  const reversedCheckCalls = checkCalls.value.slice().reverse();
  const index = reversedCheckCalls.findIndex((call: CheckCallDTO) => call.id === checkCall.id);
  return (
    checkCall.type !== CheckCallType.goodToGo &&
    index > 0 &&
    reversedCheckCalls[index - 1].facilityId !== checkCall.facilityId
  );
}

function getStopIcon(stopType?: OrderStopType): string | undefined {
  switch (stopType) {
    case OrderStopType.pickup:
      return "mdi-package-variant-closed";
    case OrderStopType.delivery:
      return "mdi-map-marker-radius-outline";
  }
}

function getStopIconColor(stopType?: OrderStopType): string | undefined {
  switch (stopType) {
    case OrderStopType.pickup:
      return "success";
    case OrderStopType.delivery:
      return "error";
  }
}

function getStopName(stop?: RouteStopDto): string {
  let name = "";
  switch (stop?.type) {
    case OrderStopType.pickup:
      name += "Pick up";
      break;
    case OrderStopType.delivery:
      name += "Delivery";
      break;
  }
  let pickUpNumber = 1,
    deliveryNumber = 1;
  for (const route of properties.trip?.routes || []) {
    for (const routeStop of route.routeStops) {
      if (routeStop.type === OrderStopType.pickup) {
        if (routeStop.id === stop?.id) {
          name += ` #${pickUpNumber}`;
          break;
        }
        pickUpNumber++;
      } else if (routeStop.type === OrderStopType.delivery) {
        if (routeStop.id === stop?.id) {
          name += ` #${deliveryNumber}`;
          break;
        }
        deliveryNumber++;
      }
    }
  }

  return name;
}

function getOngoingStopEta(): string | undefined {
  return checkCalls.value[checkCalls.value.length - 1]?.eta;
}

function showAddCheckCall(): void {
  showAddCheckCallDialog.value = true;
}
</script>

<template>
  <div class="d-flex align-center justify-space-between mb-4">
    <div class="font-size-16 font-weight-500 text-grey-darken-3">Check calls</div>
    <v-btn
      :disabled="!nextStop"
      class="font-weight-600 text-uppercase"
      color="primary"
      prepend-icon="mdi-plus"
      variant="flat"
      @click="showAddCheckCall"
    >
      Add check call
    </v-btn>
  </div>
  <omni-table
    :headers="tableHeaders"
    :items="checkCalls"
    :row-props="checkCallProps"
    :server-data="false"
    show-all-option
  >
    <template #[`header.lastCallDistance`]="{ column }: { column?: { title?: string | undefined } }">
      {{ column?.title }}
      <v-tooltip>
        <template #activator="{ props }">
          <v-icon class="ml-3" icon="mdi-information-outline" size="16" v-bind="props"></v-icon>
        </template>
        Distance indicating how much the truck deviates from<br />
        its optimal route since the last check call
      </v-tooltip>
    </template>
    <template #[`header.lastStopDistance`]="{ column }">
      {{ column.title }}
      <v-tooltip>
        <template #activator="{ props }">
          <v-icon class="ml-3" icon="mdi-information-outline" size="16" v-bind="props"></v-icon>
        </template>
        Distance indicating how much the truck deviates from its<br />
        optimal route since the last stop
      </v-tooltip>
    </template>
    <template #[`header.nextStopDistance`]="{ column }">
      {{ column.title }}
      <v-tooltip>
        <template #activator="{ props }">
          <v-icon class="ml-3" icon="mdi-information-outline" size="16" v-bind="props"></v-icon>
        </template>
        The distance indicating how much closer the truck has become<br />
        to the next stop since the last check call.
      </v-tooltip>
    </template>

    <template #[`item.type`]="{ item }: { item: CheckCallDTO }">
      <div class="d-flex align-center">
        <v-icon
          v-if="getStopIcon(item.routeStopType)"
          :color="getStopIconColor(item.routeStopType)"
          :icon="getStopIcon(item.routeStopType)"
          class="mr-2"
          size="20"
        ></v-icon>
        <div class="text-grey-darken-3" data-qa="event-name">{{ getTypeLabel(item.type) }}</div>
      </div>
    </template>
    <template #[`item.locationAddress`]="{ item }: { item: CheckCallDTO }">
      <div class="d-flex align-center">
        <div class="text-grey-darken-3 font-weight-500" data-qa="check-calls-tab-location">
          {{ item.locationAddress }}
        </div>
        <div v-if="isFacilityChanged(item)" class="d-flex align-center font-size-12 text-warning ml-4">
          <v-icon icon="mdi-alert-circle-outline"></v-icon>
          <div class="ml-2">Facility has<br />been changed</div>
        </div>
      </div>
    </template>
    <template #[`item.lastCallDistance`]="{ item }: { item: CheckCallDTO }">
      <template v-if="item.lastCallDistance">
        <span class="text-grey-darken-3 font-weight-500" data-qa="miles-from-previous-cc-total">
          {{ filters.miles(item.lastCallDistance, 0) }} miles
        </span>
        <span
          :class="getDeviationStatusClass(item.currentDeviationStatus!)"
          class="font-weight-500"
          data-qa="miles-from-previous-cc-extra"
        >
          ({{ filters.miles(item.currentDeviation, 0) }})
        </span>
        <deviation-status-label
          :deviation-status="item.currentDeviationStatus!"
          class="ml-3"
          data-qa="miles-from-previous-cc-status"
        ></deviation-status-label>
      </template>
      <template v-else> -</template>
    </template>
    <template #[`item.lastStopDistance`]="{ item }: { item: CheckCallDTO }">
      <template v-if="item.lastStopDistance">
        <span class="text-grey-darken-3 font-weight-500" data-qa="miles-from-last-stop-total">
          {{ filters.miles(item.lastStopDistance, 0) }} miles
        </span>
        <span
          :class="getDeviationStatusClass(item.accumulatedDeviationStatus!)"
          class="font-weight-500"
          data-qa="miles-from-last-stop-extra"
        >
          ({{ filters.miles(item.accumulatedDeviation, 0) }})
        </span>
        <deviation-status-label
          :deviation-status="item.accumulatedDeviationStatus!"
          class="ml-3"
          data-qa="miles-from-last-stop-status"
        ></deviation-status-label>
      </template>
      <template v-else> -</template>
    </template>
    <template #[`item.nextStopDistance`]="{ item }: { item: CheckCallDTO }">
      <template v-if="item.nextStopDistance">
        <span class="text-grey-darken-3 font-weight-500" data-qa="miles-left-next-stop-total">
          {{ filters.miles(item.nextStopDistance, 0) }}
        </span>
        <span
          :class="{ 'text-success': item.distanceReducing! < 0, 'text-error': item.distanceReducing! >= 0 }"
          class="ml-2 font-weight-500"
          data-qa="miles-left-next-stop-extra"
        >
          {{ filters.miles(item.distanceReducing, 0) }} miles
        </span>
      </template>
      <template v-else> -</template>
    </template>
    <template #[`item.avgSpeed`]="{ item }: { item: CheckCallDTO }">
      <span class="text-grey-darken-3 font-weight-500" data-qa="avg-speed">{{ filters.miles(item.avgSpeed, 0) }}</span>
    </template>
    <template #[`item.dateTime`]="{ item }: { item: CheckCallDTO }">
      <check-call-date-time :check-call="item" />
    </template>
    <template #[`item.createdAt`]="{ item }: { item: CheckCallDTO }">
      <div class="text-grey-darken-3" data-qa="created-at-date">
        {{ dateUtils.dateTZ(item.createdAt, appStore.dispatcher!.timezone) }}
      </div>
      <div
        v-if="item.createdDispatcher"
        class="text-primary font-size-12 font-weight-500"
        data-qa="cc-tab-dispatcher-name"
      >
        {{ item.createdDispatcher.name }} (Dispatcher)
      </div>
      <router-link
        v-else-if="item.createdUser"
        :to="{ name: 'user-view', params: { id: item.createdUser.id } }"
        class="text-primary font-size-12 font-weight-500 text-decoration-none"
        target="_blank"
      >
        {{ item.createdUser.name }} (User)
      </router-link>
      <div v-else class="text-primary font-size-12 font-weight-500">System</div>
    </template>
    <template #bottom>
      <div v-if="nextStop" class="bg-green-lighten-5 d-flex justify-space-between bottom-row">
        <div class="text-blue-grey-darken-1 font-weight-500">Next stop</div>
        <div>
          <div class="d-flex align-center mb-2">
            <v-icon
              v-if="getStopIcon(nextStop!.type)"
              :color="getStopIconColor(nextStop!.type)"
              :icon="getStopIcon(nextStop!.type)"
              class="mr-2"
              size="20"
            ></v-icon>
            <div class="text-blue-grey-darken-1 font-weight-500" data-qa="next-stop-type">
              {{ getStopName(nextStop) }}
            </div>
          </div>
          <router-link
            :to="{ name: 'order-view', params: { id: nextStop?.order?.id } }"
            class="text-decoration-none text-primary font-size-12 font-weight-500"
            data-qa="order-number"
            target="_blank"
          >
            Order #{{ nextStop?.order?.number }}
          </router-link>
        </div>
        <facility-info v-if="nextStop?.facility" :facility="nextStop.facility as OrderFacilityDto"></facility-info>
        <div>
          <div class="text-grey-darken-2 mb-2">Time:</div>
          <div class="d-flex text-grey-darken-3 font-weight-500">
            <div class="mr-1" data-qa="next-stop-time-type">{{ orderUtils.getTimeType(nextStop!.timeType) }}:</div>
            <div>
              <div data-qa="next-stop-time-1">
                {{ orderUtils.getTimeInterval(nextStop!.timeFrom, nextStop!.timeTo, appStore.dispatcher!.timezone) }}
              </div>
              <div data-qa="next-stop-time-2">
                {{ orderUtils.getTimeInterval(nextStop!.timeFrom, nextStop!.timeTo, nextStop!.timezone) }}
              </div>
            </div>
          </div>
          <div v-if="nextStop?.time2From" class="d-flex text-grey-darken-3 font-weight-500">
            <div class="mr-1">{{ orderUtils.getTimeType(nextStop!.time2Type!) }}:</div>
            <div>
              <div>
                {{ orderUtils.getTimeInterval(nextStop!.time2From, nextStop!.time2To, appStore.dispatcher!.timezone) }}
              </div>
              <div>{{ orderUtils.getTimeInterval(nextStop!.time2From, nextStop!.time2To, nextStop!.timezone) }}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="text-grey-darken-2 mb-2">ETA:</div>
          <div v-if="getOngoingStopEta()" class="border-success rounded py-1 px-2 text-success font-weight-500">
            <div data-qa="next-stop-eta-time-1">
              {{ dateUtils.dateTZ(getOngoingStopEta()!, appStore.dispatcher!.timezone) }}
            </div>
            <div data-qa="next-stop-eta-time-2">{{ dateUtils.dateTZ(getOngoingStopEta()!, nextStop!.timezone) }}</div>
          </div>
        </div>
      </div>
    </template>
  </omni-table>
  <add-check-call-dialog
    v-model="showAddCheckCallDialog"
    :route-id="routeId"
    @change="loadCheckCalls"
  ></add-check-call-dialog>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.bottom-row {
  padding: 7px 16px;
  border: 1px solid $grey-lighter;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
