<script lang="ts" setup>
import { defineProps, withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    alignTabs?: "center" | "end" | "start" | "title";
  }>(),
  {
    alignTabs: "title",
  },
);

const model = defineModel<any>();
</script>

<template>
  <v-tabs v-model="model" :align-tabs="props.alignTabs" bg-color="white" color="primary" height="56">
    <slot></slot>
  </v-tabs>
</template>

<style lang="scss" scoped>
.v-tabs-items {
  background-color: transparent !important;
}

:deep(.v-tab) {
  font-weight: 400;
  text-transform: uppercase;

  &.v-tab--selected .v-tab__slider {
    height: 4px;
  }
}
</style>
