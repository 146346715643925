<script>
import DispatcherSelect from "@/components/inputs/DispatcherSelect.vue";
import FormBlock from "@/components/form/FormBlock.vue";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import CustomerSelect from "@/views/orders/components/CustomerSelect.vue";
import OrderRefNumberInput from "@/views/orders/components/OrderRefNumberInput.vue";
import OrderSendToInput from "@/views/orders/components/OrderSendToInput.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { OrdersMixin } from "@/views/orders/orders.mixin";
import OrderTemperatureRange from "@/views/orders/components/edit/OrderTemperatureRange.vue";
import { mapState } from "pinia";
import { useAppStore } from "@/store/app.store";

export default {
  name: "OrderEditInfo",
  components: {
    OrderTemperatureRange,
    OrderSendToInput,
    OrderRefNumberInput,
    CustomerSelect,
    OmniSelect,
    OmniTextarea,
    FormBlock,
    DispatcherSelect,
  },
  mixins: [ValidationMixin, OrdersMixin],
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      billToCustomer: null,
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
    order: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    temperatureFrom: {
      get() {
        return this.order.temperatures[0] || "";
      },
      set(value) {
        this.order.temperatures[0] = value;
      },
    },
    temperatureTo: {
      get() {
        return this.order.temperatures[1] || "";
      },
      set(value) {
        this.order.temperatures[1] = value;
      },
    },
    checkInAsList() {
      const items = [this.dispatcher.company.name];
      if (this.billToCustomer && this.billToCustomer.name) {
        items.push(this.billToCustomer.name);
      }
      return items;
    },
  },
  methods: {
    updateCustomer(customer) {
      this.billToCustomer = { ...customer };
    },
  },
};
</script>

<template>
  <form-block>
    <div class="caption text-grey-darken-1 mb-4">Load information</div>
    <div class="mb-5">
      <v-row>
        <v-col lg="3" md="4" xl="3">
          <dispatcher-select
            v-model="order.bookedBy"
            :rules="[requiredValidator]"
            data-qa="booked_by"
            label="Booked by"
            required
          ></dispatcher-select>
        </v-col>
        <v-col lg="3" md="4" xl="3">
          <v-text-field
            v-model="order.rate"
            :rules="[digitsValidator, requiredValidator]"
            append-inner-icon="mdi-currency-usd"
            class="required"
            color="primary"
            data-qa="rate"
            density="compact"
            hide-details="auto"
            label="Total rate"
            name="rate"
            variant="outlined"
          />
        </v-col>
        <v-col lg="6" md="4" xl="6">
          <customer-select
            v-model="order.billToCustomerId"
            :rules="[requiredValidator]"
            data-qa="bill_to_customer_id"
            editable
            label="Bill to company"
            required
            @update="updateCustomer"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="6" md="6" xl="6">
          <order-ref-number-input v-model="order.refNumbers" data-qa="ref_numbers"></order-ref-number-input>
        </v-col>
        <v-col lg="6" md="6" xl="6">
          <order-send-to-input
            v-model="order.sendUpdatesTo"
            :errors="order.sendUpdatesTo.$error"
            data-qa="send_updates_to"
          ></order-send-to-input>
        </v-col>
      </v-row>
    </div>
    <div class="mb-5">
      <omni-textarea v-model="order.generalNote" data-qa="general-note" label="General note" rows="4" />
    </div>
    <div class="additional-info">
      <div class="caption text-grey-darken-1 mb-4">Additional information</div>
      <v-row class="align-center">
        <v-col lg="3" md="12" xl="3">
          <v-combobox
            v-model="order.checkInAs"
            :items="checkInAsList"
            :rules="[requiredValidator]"
            class="required"
            color="primary"
            data-qa="check_in_as"
            density="compact"
            hide-details="auto"
            label="Check in as"
            variant="outlined"
          />
        </v-col>
        <v-col lg="3" md="12" xl="2">
          <omni-select
            v-model="order.requiredDriverCertificates"
            :items="certificates"
            :multiple="true"
            data-qa="required_driver_certificates"
            label="Required Certificates"
          ></omni-select>
        </v-col>
        <v-col lg="8" md="12" xl="5">
          <div class="d-flex align-center">
            <v-checkbox
              v-model="order.underTracking"
              append-icon="mdi-map-marker-outline"
              class="body-2 pa-0 ma-0 mr-4 d-flex align-center"
              color="primary"
              data-qa="under_tracking"
              density="compact"
              hide-details
              label="Under tracking"
            />
            <v-checkbox
              v-model="order.teamForBroker"
              append-icon="mdi-account-multiple"
              class="text--darken-1 body-2 pa-0 ma-0 mr-4 d-flex align-center"
              color="primary"
              data-qa="team_for_broker"
              density="compact"
              hide-details
              label="Team for broker"
            />
            <v-checkbox
              v-model="order.hazmat"
              class="pa-0 ma-0 mr-4 d-flex align-center"
              color="primary"
              data-qa="hazmat"
              density="compact"
              hide-details
              label="Hazmat commodity"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-checkbox
            v-model="order.$hasTemperatureControl"
            class="pa-0 ma-0"
            color="primary"
            data-qa="temperature-control"
            density="compact"
            hide-details
            label="Temperature control"
          />
        </v-col>
      </v-row>
      <v-row v-if="order.$hasTemperatureControl">
        <v-col lg="3" md="12" xl="3">
          <order-temperature-range
            v-model="order.temperatures"
            :disabled="order.protectFromFreezing"
          ></order-temperature-range>
        </v-col>
        <v-col lg="3" md="12" xl="2">
          <v-checkbox
            v-model="order.protectFromFreezing"
            class="pa-0 ma-0"
            color="primary"
            data-qa="protect_from_freezing"
            density="compact"
            hide-details
            label="Protect from freezing"
          />
        </v-col>
      </v-row>
    </div>
  </form-block>
</template>

<style lang="scss" scoped>
.additional-info {
  .v-input__append-outer {
    margin-left: 4px;

    .v-input__icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }
}
</style>
