<script>
import FacilitySelect from "@/views/orders/components/FacilitySelect.vue";
import FormBlock from "@/components/form/FormBlock.vue";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import { OrdersMixin } from "@/views/orders/orders.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import OrderStopStatus from "@/components/status/OrderStopStatus.vue";
import BaseView from "@/views/BaseView.vue";
import {
  OrderFreightState as FreightState,
  OrderStatus,
  OrderStopState as StopState,
  OrderStopStatus as StopStatus,
  OrderStopType,
} from "@/data/order";
import OrderStopState from "@/components/status/OrderStopState.vue";
import StopDateRange from "@/views/orders/components/StopDateRange.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import OrderFreightState from "@/views/orders/components/edit/OrderFreightState.vue";

export default {
  name: "OrderEditStops",
  components: {
    OrderFreightState,
    AlertMessage,
    StopDateRange,
    OrderStopState,
    OrderStopStatus,
    OmniTextarea,
    FormBlock,
    FacilitySelect,
  },
  mixins: [BaseView, OrdersMixin, ValidationMixin],
  props: {
    originalOrder: Object,
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      originalFreights: [],
    };
  },
  computed: {
    StopState() {
      return StopState;
    },
    order: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    stops: {
      get() {
        let pickUpKey = 0,
          deliveryKey = 0;
        const orderStops = this.order.orderStops;
        return orderStops
          .sort((a, b) => a.position - b.position)
          .map((orderStop) => {
            let key;
            if (orderStop.type === this.STOP_PICK_UP) {
              key = ++pickUpKey;
            } else if (orderStop.type === this.STOP_DELIVERY) {
              key = ++deliveryKey;
            }
            orderStop.$key = key;
            if (orderStop.time2From || orderStop.time2To) {
              orderStop.$showSecondDate = true;
            }
            return orderStop;
          });
      },
      set(value) {
        this.order.orderStops = value;
      },
    },
    freights() {
      return this.order.freights.map((freight, index) => {
        freight.$number = index + 1;
        const freightPickupStop = this.stops.find(
          (s) => s.type === OrderStopType.pickup && s.freights.includes(freight.id),
        );
        if (freightPickupStop) {
          const freightNameChunks = ["#" + freight.$number + ":", "PU#" + freightPickupStop.$key];
          if (freightPickupStop.facility?.address) {
            freightNameChunks.push("(" + freightPickupStop.facility.address + ")");
          }
          if (freight.quantity) {
            freightNameChunks.push(freight.quantity + " " + freight.type);
          }
          if (freight.weight) {
            freightNameChunks.push(freight.weight + " lbs");
          }
          freight.$name = freightNameChunks.join(" ");
        }
        freight.$usedIn = this.stops
          .filter((s) => s.type === OrderStopType.delivery && s.freights.includes(freight.id))
          .map((s) => s.$key);
        return freight;
      });
    },
  },
  watch: {
    freights: {
      handler() {
        if (this.originalFreights.length === 0 && this.originalOrder) {
          this.originalFreights = this.freights.map((f) => ({ ...f }));
        }
        for (const freight of this.freights) {
          if (freight.length === "") freight.length = null;
          if (freight.width === "") freight.width = null;
          if (freight.height === "") freight.height = null;

          const originalFreight = this.originalFreights.find((f) => f.id === freight.id);
          if (originalFreight?.state === FreightState.updated) continue;
          if (originalFreight) {
            freight.state = this.compareFreights(freight, originalFreight) ? freight.state : FreightState.updated;
          } else {
            freight.state = FreightState.new;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    compareFreights(freight1, freight2) {
      return (
        freight1.type === freight2.type &&
        freight1.stackable === freight2.stackable &&
        parseInt(freight1.quantity) === parseInt(freight2.quantity) &&
        parseInt(freight1.weight) === parseInt(freight2.weight)
      );
    },
    getStopFreights(stop) {
      return stop.freights
        .map((freightId) => this.freights.find((f) => f.id === freightId))
        .filter((f) => !(!this.order.tripId && f.isDeleted));
    },
    getStopPallets(stop) {
      return this.getStopFreights(stop)
        .filter((f) => f.type === this.freightTypePallets)
        .map((f) => f.quantity)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    },
    getStopPieces(stop) {
      return this.getStopFreights(stop)
        .filter((f) => f.type === this.freightTypePieces)
        .map((f) => f.quantity)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    },
    getStopWeight(stop) {
      return this.getStopFreights(stop)
        .map((f) => f.weight)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    },
    isStopDeletable(stop) {
      return (
        !stop.isDeleted &&
        stop.status === StopStatus.notStarted &&
        this.stops.filter((s) => s.type === stop.type).length > 1
      );
    },
    async addFreight(stop) {
      const id = this.generateId();
      const freight = {
        id: id,
        type: this.freightTypePallets,
        quantity: 1,
        weight: 1,
        stackable: false,
        state: FreightState.new,
      };

      this.order.freights.push(freight);
      stop.freights.push(freight.id);
    },
    removeFreight(freight) {
      freight.isDeleted = true;
      if (
        !this.order.tripId ||
        (this.order.tripId && !(this.originalFreights.findIndex((f) => f.id === freight.id) > -1))
      ) {
        this.order.orderStops.forEach((stop) => {
          const stopFreightIndex = stop.freights.indexOf(freight.id);
          if (stopFreightIndex > -1) {
            stop.freights.splice(stopFreightIndex, 1);
          }
        });
      }
    },
    async addStop(stopType) {
      const stop = {
        id: this.generateId(),
        type: stopType,
        state: StopState.new,
        status: StopStatus.notStarted,
        freights: [],
        note: "",
        timeType: "f",
        timeFrom: null,
        timeTo: null,
        time2Type: "f",
        time2From: null,
        time2To: null,
        position: (Math.max(...this.stops.map((s) => s.position)) || 0) + 1,
      };
      if (stopType === this.STOP_PICK_UP) await this.addFreight(stop);
      this.order.orderStops.push(stop);
    },
    deleteStop(stop) {
      if (this.isStopDeletable(stop) && confirm("Are you sure to delete stop?")) {
        const indexToDelete = this.order.orderStops.findIndex((s) => s.id === stop.id);
        if (indexToDelete > -1) {
          const stopToDelete = this.order.orderStops[indexToDelete];

          stopToDelete.isDeleted = true;
          if (stopToDelete.type === OrderStopType.pickup) {
            this.order.freights
              .filter((f) => stopToDelete.freights.includes(f.id))
              .forEach((f) => (f.isDeleted = true));
          }
        }
      }
    },
    getFreightsForStop(stop, index) {
      const pickedBeforeFreights = this.stops
        .slice(0, index)
        .filter((s) => s.type === OrderStopType.pickup)
        .flatMap((s) => s.freights);
      return this.freights
        .filter((f) => pickedBeforeFreights.includes(f.id) && !(!this.order.tripId && f.isDeleted))
        .map((f) => {
          const usedIn = (f.$usedIn || []).filter((f) => f !== stop.$key);
          const suffix =
            usedIn.length > 0
              ? "Freight already selected at " + usedIn.map((i) => "Delivery#" + i).join(", ") + " stop(s)"
              : null;
          const disabled =
            (this.order.status !== OrderStatus.unassigned &&
              this.originalFreights.flatMap((of) => of.id).includes(f.id)) ||
            f.isDeleted;
          return {
            ...f,
            suffix,
            disabled,
          };
        });
    },
    isStopEditable(stop) {
      return (!this.order.id || StopStatus.notStarted === stop.status) && !stop.isDeleted;
    },
    getSavedStop(stop) {
      return this.originalOrder?.orderStops.find((s) => s.id === stop.id);
    },
    isStopSaved(stop) {
      return !!this.getSavedStop(stop);
    },
    isStopUpdated(stop) {
      if (!this.originalOrder || !this.originalOrder.tripId) return false;
      if (stop.state === StopState.updated) return true;

      const originalStop = this.getSavedStop(stop);
      if (!originalStop) {
        return false;
      }
      const freights = this.getStopFreights(stop);
      return (
        originalStop.facilityId !== stop.facilityId ||
        freights.some((f) => f.state !== FreightState.normal || f.isDeleted)
      );
    },
    isStopVisible(stop) {
      return !(stop.isDeleted && !this.order.tripId);
    },
    getStopDeletedFreights(stop) {
      return stop.freights.map((freightId) => this.freights.find((f) => f.id === freightId)).filter((f) => f.isDeleted);
    },
    facilityUpdated(stop, facility) {
      stop.timezone = facility.timezone;
      stop.facility = facility;
    },
    getFreightStateClass(freight) {
      if (!this.order.tripId || !this.order.id) {
        return {};
      }
      return {
        "freight--new": freight.state === FreightState.new,
        "freight--updated": freight.state === FreightState.updated && !freight.isDeleted,
        "freight--deleted": freight.isDeleted,
      };
    },
    moveUpBlocked(stop) {
      const stopIndex = this.stops.findIndex((s) => s.id === stop.id);
      const previousStopIndex = stopIndex - 1;
      const previousStop = this.stops[previousStopIndex];
      const result = {
        blocked: false,
        message: null,
      };
      if (!previousStop) {
        result.blocked = true;
        result.message = "The stop is first in order";
        return result;
      }
      if (stopIndex === 1 && stop.type === OrderStopType.delivery) {
        result.blocked = true;
        result.message = "Delivery can't be the first stop in a order";
        return result;
      }
      if (this.isStopSaved(stop) && this.isStopSaved(previousStop)) {
        result.blocked = true;
        result.message = "Both stops have been already saved (You can change position only for not saved stops)";
        return result;
      }
      if (
        stop.type === OrderStopType.delivery &&
        previousStop.type === OrderStopType.pickup &&
        stop.freights.filter((freightId) => previousStop.freights.includes(freightId)).length > 0
      ) {
        result.blocked = true;
        result.message = "Freight(s) is/are at the both stops";
        return result;
      }
      return result;
    },
    moveDownBlocked(stop) {
      const stopIndex = this.stops.findIndex((s) => s.id === stop.id);
      const nextStopIndex = stopIndex + 1;
      const nextStop = this.stops[nextStopIndex];
      const result = {
        blocked: false,
        message: null,
      };
      if (!nextStop) {
        result.blocked = true;
        result.message = "The stop is last in order";
        return result;
      }
      if (stopIndex === this.stops.length - 2 && stop.type === OrderStopType.pickup) {
        result.blocked = true;
        result.message = "Pickup can't be the the last stop in a order.";
        return result;
      }
      if (this.isStopSaved(stop) && this.isStopSaved(nextStop)) {
        result.blocked = true;
        result.message = "Both stops have been already saved (You can change position only for not saved stops)";
        return result;
      }
      if (
        stop.type === OrderStopType.pickup &&
        nextStop.type === OrderStopType.delivery &&
        stop.freights.filter((freightId) => nextStop.freights.includes(freightId)).length > 0
      ) {
        result.blocked = true;
        result.message = "Freight(s) is/are at the both stops";
        return result;
      }
      return result;
    },
    moveUp(stop) {
      const stopIndex = this.stops.findIndex((s) => s.id === stop.id);
      const previousStopIndex = stopIndex - 1;
      const previousStop = this.stops[previousStopIndex];
      [previousStop.position, stop.position] = [stop.position, previousStop.position];
    },
    moveDown(stop) {
      const stopIndex = this.stops.findIndex((s) => s.id === stop.id);
      const nextStopIndex = stopIndex + 1;
      const nextStop = this.stops[nextStopIndex];
      [nextStop.position, stop.position] = [stop.position, nextStop.position];
    },
  },
};
</script>

<template>
  <div class="mb-4">
    <div class="d-flex align-center justify-space-between mb-4">
      <div class="font-size-16 text-grey-darken-3 font-weight-medium">Stops</div>
      <div class="d-flex align-center">
        <v-btn
          class="text-uppercase text-green font-size-12 font-weight-600 pr-2 pl-2"
          color="green"
          height="28"
          variant="outlined"
          @click="addStop(STOP_PICK_UP)"
        >
          <v-icon class="mr-2" color="green" small> mdi-package-variant-closed</v-icon>
          Add pick up
        </v-btn>

        <v-btn
          class="text-uppercase text-red text--accent-2 font-size-12 font-weight-600 pr-2 pl-2 ml-4"
          color="red-accent-2"
          height="28"
          variant="outlined"
          @click="addStop(STOP_DELIVERY)"
        >
          <v-icon class="mr-2" color="red-accent-2" size="small"> mdi-map-marker-radius-outline</v-icon>
          Add delivery
        </v-btn>
      </div>
    </div>
    <template v-for="(stop, index) in stops" :key="stop.id">
      <div v-if="isStopVisible(stop)">
        <form-block
          :class="{
            'v-input--error': stop.$error || stop.freights?.some((f) => f.$error) || stop.$timeError,
          }"
          class="form-block"
        >
          <div class="d-flex align-center justify-space-between mb-4">
            <div :class="{ 'text-grey-lighten-1': stop.isDeleted }" class="d-flex align-center">
              <v-icon
                v-if="stop.type === STOP_PICK_UP"
                :color="stop.isDeleted ? 'grey-lighten-1' : 'green'"
                class="mr-2"
                size="20"
              >
                mdi-package-variant-closed
              </v-icon>
              <v-icon
                v-if="stop.type === STOP_DELIVERY"
                :color="stop.isDeleted ? 'grey-lighten-1' : 'red accent-2'"
                class="mr-2"
                size="20"
              >
                mdi-map-marker-radius-outline
              </v-icon>
              <span class="font-size-11 mr-1">Stop {{ index + 1 }} - </span>
              <span v-if="stop.type === STOP_PICK_UP" class="text-caption font-weight-600">
                Pick up #{{ stop.$key }}</span
              >
              <span v-if="stop.type === STOP_DELIVERY" class="text-caption font-weight-600">
                Delivery #{{ stop.$key }}</span
              >
              <order-stop-status v-if="order.id" :stop="stop" class="ml-2" hide-edge-statuses></order-stop-status>
              <order-stop-state
                v-if="order.id && order.tripId"
                :state="isStopUpdated(stop) ? StopState.updated : null"
                :stop="stop"
                class="ml-2"
              ></order-stop-state>
            </div>
            <div class="d-flex align-center">
              <v-btn v-if="isStopDeletable(stop)" class="delete-btn pa-0 mr-4" variant="text" @click="deleteStop(stop)">
                <v-icon class="mr-1" color="red-darken-1" size="20">mdi-delete-outline</v-icon>
                <span class="text-red-darken-1">DELETE STOP</span>
              </v-btn>
              <div class="d-flex flex-column">
                <v-tooltip :disabled="!moveUpBlocked(stop).message" location="top">
                  <template #activator="{ props }">
                    <div v-bind="props">
                      <v-btn
                        :disabled="moveUpBlocked(stop).blocked"
                        icon="mdi-chevron-up"
                        size="xs"
                        variant="plain"
                        @click="moveUp(stop)"
                      ></v-btn>
                    </div>
                  </template>
                  {{ moveUpBlocked(stop).message }}
                </v-tooltip>

                <v-tooltip :disabled="!moveDownBlocked(stop).message" location="top">
                  <template #activator="{ props }">
                    <div v-bind="props">
                      <v-btn
                        :disabled="moveDownBlocked(stop).blocked"
                        icon="mdi-chevron-down"
                        size="xs"
                        variant="plain"
                        @click="moveDown(stop)"
                      ></v-btn>
                    </div>
                  </template>
                  {{ moveDownBlocked(stop).message }}
                </v-tooltip>
              </div>
            </div>
          </div>

          <v-row>
            <v-col lg="3" sm="12">
              <facility-select
                v-model="stop.facilityId"
                :readonly="!isStopEditable(stop)"
                :rules="[requiredValidator]"
                coordinates-tooltip="Coordinates for calculating loaded miles"
                editable
                label="Facility"
                @update="(f) => facilityUpdated(stop, f)"
              />
            </v-col>
            <v-col lg="9" sm="12">
              <div class="stops-right__top">
                <stop-date-range
                  v-model="stops[index]"
                  :disabled="!isStopEditable(stop)"
                  :error="stop.$timeError"
                ></stop-date-range>
                <v-select
                  v-if="stop.type === STOP_DELIVERY"
                  v-model="stop.freights"
                  :disabled="!isStopEditable(stop)"
                  :items="getFreightsForStop(stop, index)"
                  :multiple="true"
                  :rules="[requiredValidator]"
                  chips
                  class="my-3 required"
                  color="primary"
                  density="compact"
                  hide-details="auto"
                  item-title="$name"
                  item-value="id"
                  label="Freight"
                  variant="outlined"
                >
                  <template #chip="{ item, index: freightIndex }">
                    <v-chip
                      :class="getFreightStateClass(item.raw)"
                      :closable="!originalFreights.flatMap((of) => of.id).includes(item.value)"
                      class="font-weight-500 text-grey-darken-2"
                      close-icon="mdi-close"
                      @click:close="() => stop.freights.splice(freightIndex, 1)"
                    >
                      <span :class="getFreightStateClass(item.raw)" class="freight-dot mr-1">●</span>
                      <span :class="{ 'text-strikethrough text-grey-lighten-1': item.raw.isDeleted }">
                        {{ item.title }}
                      </span>
                    </v-chip>
                  </template>
                  <template #item="{ item, props: itemProps }">
                    <v-list-item
                      :disabled="item.raw.disabled"
                      :label="item.title"
                      :value="item.value"
                      v-bind="itemProps"
                    >
                      <template #prepend="status">
                        <v-checkbox-btn
                          :key="item.value"
                          :color="!item.raw.disabled ? 'primary' : 'grey-darken-3'"
                          :model-value="status.isSelected"
                          :ripple="false"
                          tabindex="-1"
                        />
                      </template>
                      <template #title>
                        <div
                          :class="{
                            'text-grey-darken-3': item.raw.disabled,
                            'text-strikethrough': item.raw.isDeleted,
                          }"
                        >
                          {{ item.title }}
                        </div>
                      </template>
                      <template v-if="typeof item.raw === 'object' && 'suffix' in item.raw" #append>
                        <div class="text-grey-darken-2">{{ item.raw.suffix }}</div>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
                <div v-if="stop.type === STOP_PICK_UP">
                  <div class="frights-list pt-2">
                    <div
                      v-for="(freight, freightIndex) in getStopFreights(stop)"
                      :key="freight?.id"
                      :class="getFreightStateClass(freight)"
                      class="freight-block"
                    >
                      <div class="freight-header">
                        <div class="d-flex align-center">
                          <div class="text-caption text-grey-darken-1">Freight #{{ freight.$number }}:</div>
                          <order-freight-state
                            v-if="order.tripId"
                            :freight="freight"
                            class="ml-2"
                          ></order-freight-state>

                          <div v-if="freight.$error" class="text-red-lighten-2 ml-2" data-qa="alert-message">
                            {{ freight.$error }}
                          </div>
                          <v-spacer />
                          <v-btn
                            v-if="freightIndex === 0"
                            :disabled="!isStopEditable(stop)"
                            class="text-uppercase text-caption text-primary font-weight-600 pr-2 pl-1 add-freight__btn"
                            variant="text"
                            @click="addFreight(stop)"
                          >
                            <v-icon class="mr-2" color="primary" small>mdi-plus</v-icon>
                            ADD FREIGHT
                          </v-btn>
                          <v-btn
                            v-if="stop.freights.length > 1"
                            :disabled="!isStopEditable(stop) || freight.isDeleted"
                            class="text-uppercase text-caption text-red-lighten-1 font-weight-600 pr-2 pl-1 add-freight__btn"
                            variant="text"
                            @click="removeFreight(freight)"
                          >
                            <v-icon class="mr-2" color="red-lighten-1" small>mdi-delete-outline</v-icon>
                            Delete freight
                          </v-btn>
                        </div>
                        <v-radio-group
                          v-model="freight.type"
                          :disabled="!isStopEditable(stop) || freight.isDeleted"
                          :inline="true"
                          class="mb-2"
                          color="primary"
                          density="compact"
                          hide-details
                        >
                          <v-radio :value="freightTypePallets">
                            <template #label>
                              <span class="text-caption">Pallets</span>
                            </template>
                          </v-radio>
                          <v-radio :value="freightTypePieces">
                            <template #label>
                              <span class="text-caption">Pieces</span>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <div class="freight-body">
                        <div>
                          <div class="d-flex align-center justify-space-between flex-wrap">
                            <v-row class="align-center medium-padding">
                              <v-col lg="10" md="10" sm="10">
                                <v-row class="medium-padding">
                                  <v-col lg="4" sm="6">
                                    <v-row class="medium-padding">
                                      <v-col sm="6" xl="6">
                                        <v-text-field
                                          v-model="freight.quantity"
                                          :disabled="!isStopEditable(stop) || freight.isDeleted"
                                          :rules="[requiredValidator, positiveNumber]"
                                          class="required"
                                          color="primary"
                                          data-qa="planned_quantity"
                                          density="compact"
                                          hide-details="auto"
                                          label="Quantity"
                                          required
                                          variant="outlined"
                                        />
                                      </v-col>
                                      <v-col sm="6" xl="6">
                                        <v-text-field
                                          v-model="freight.weight"
                                          :disabled="!isStopEditable(stop) || freight.isDeleted"
                                          :rules="[requiredValidator, positiveNumber]"
                                          class="required"
                                          color="primary"
                                          data-qa="planned_weight"
                                          density="compact"
                                          hide-details="auto"
                                          label="Total weight"
                                          variant="outlined"
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col lg="5" sm="6">
                                    <v-row>
                                      <v-col class="dimension-part">
                                        <v-text-field
                                          v-model="freight.length"
                                          :disabled="!isStopEditable(stop) || freight.isDeleted"
                                          color="primary"
                                          data-qa="length"
                                          density="compact"
                                          hide-details
                                          label="Length"
                                          variant="outlined"
                                        />
                                      </v-col>
                                      <v-col class="dimension-part">
                                        <v-text-field
                                          v-model="freight.width"
                                          :disabled="!isStopEditable(stop) || freight.isDeleted"
                                          color="primary"
                                          data-qa="width"
                                          density="compact"
                                          hide-details
                                          label="Width"
                                          variant="outlined"
                                        />
                                      </v-col>
                                      <v-col>
                                        <v-text-field
                                          v-model="freight.height"
                                          :disabled="!isStopEditable(stop) || freight.isDeleted"
                                          color="primary"
                                          data-qa="height"
                                          density="compact"
                                          hide-details
                                          label="Height"
                                          variant="outlined"
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col lg="2" md="2" sm="2">
                                <div class="d-flex align-center justify-end">
                                  <v-checkbox
                                    v-model="freight.stackable"
                                    :disabled="!isStopEditable(stop) || freight.isDeleted"
                                    :false-value="true"
                                    :true-value="false"
                                    class="pa-0 ma-0 mr-4 stackable"
                                    color="primary"
                                    data-qa="stackable"
                                    hide-details
                                  >
                                    <template #label>
                                      <span class="text-caption">Not stackable</span>
                                    </template>
                                  </v-checkbox>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mb-5">
                    <div class="mr-2 bg-blue-grey-lighten-5 pt-2 pb-2 pr-3 pl-3 rounded-lg total-block">
                      <div class="text-grey-darken-2 text-caption mr-1">
                        Total quantity:<br />
                        <span v-if="getStopPallets(stop) > 0" class="font-weight-medium mr-3">
                          {{ getStopPallets(stop) }} pallets
                        </span>
                        <span v-if="getStopPieces(stop) > 0" class="font-weight-medium">
                          {{ getStopPieces(stop) }} pieces
                        </span>
                      </div>
                    </div>
                    <div class="bg-blue-grey-lighten-5 pt-2 pb-2 pr-3 pl-3 rounded-lg total-block">
                      <div class="text-grey-darken-2 text-caption mr-1">
                        Total weight:
                        <div class="font-weight-medium">{{ getStopWeight(stop) }} lbs</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="stops-right__bottom text-area-wrapper">
                <omni-textarea
                  v-model="stop.note"
                  :disabled="!isStopEditable(stop)"
                  data-qa="stop-notes"
                  label="Order stop note"
                  rows="3"
                ></omni-textarea>
              </div>
            </v-col>
          </v-row>
          <alert-message
            v-for="deletedFreight in getStopDeletedFreights(stop)"
            :key="deletedFreight.id"
            bg-color="indigo-lighten-5"
            border-color="none"
            class="mt-3"
            color="indigo"
            icon="mdi-information-outline"
            icon-color="indigo"
          >
            Freight#{{ deletedFreight.$number }} will be removed after re-dispatch confirmation
          </alert-message>
          <alert-message
            v-if="stop.isDeleted"
            bg-color="indigo-lighten-5"
            border-color="none"
            class="mt-3"
            color="indigo"
            icon="mdi-information-outline"
            icon-color="indigo"
          >
            Stop will be removed after dispatch changes confirmation
          </alert-message>
        </form-block>
        <div
          v-if="stop.$error"
          class="text-red-lighten-2 mb-8 mt-n2 d-flex align-center"
          data-qa="alert-message-last-pickup"
        >
          <v-icon icon="mdi-alert-octagon-outline mr-2"></v-icon>
          {{ stop.$error }}
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.total-block {
  min-width: 187px;
}

.form-block {
  &.v-input--error {
    border: 1px solid $danger;
  }
}

.freight-block {
  border: 1px solid $grey-light;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 16px;

  &.freight--new {
    border-color: $green;
  }

  &.freight--updated {
    border-color: $orange;
  }
}

.v-chip {
  &.freight--new {
    border: 1px solid $green;
  }

  &.freight--updated {
    border: 1px solid $orange;
  }
}

.freight-dot {
  display: none;

  &.freight--new {
    display: block;
    color: $green;
  }

  &.freight--updated {
    display: block;
    color: $orange;
  }

  &.freight--deleted {
    display: block;
    color: $green-light;
  }
}

.dimension-part {
  position: relative;

  &:after {
    content: "х";
    position: absolute;
    top: 50%;
    right: -4px;
    transform: translateY(-50%);
  }
}

.stackable {
  :deep(.v-label) {
    white-space: nowrap;
  }
}

.medium-padding {
  margin: -7px -8px;

  & > [class*="col-"],
  & > .col {
    padding: 7px 8px;
  }
}

.height-full__bottom,
.stops-right__bottom {
  .v-input {
    height: 100%;

    .v-input__control {
      height: 100%;
    }
  }
}

.stops-right__bottom {
  .v-input__slot {
    height: 100%;
  }
}
</style>
