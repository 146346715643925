<script>
import OrderBlock from "@/views/trips/edit/blocks/OrderBlock.vue";
import TravelOrderBlock from "@/views/trips/edit/blocks/TravelOrderBlock.vue";
import RouteBlock from "@/views/trips/edit/blocks/RouteBlock.vue";
import AddOrderDialog from "@/views/trips/edit/dialogs/AddOrderDialog.vue";
import ReorderOrderStops from "@/views/trips/edit/dialogs/ReorderOrderStops.vue";
import { OrdersMixin } from "@/views/orders/orders.mixin";
import AddReloadStopDialog from "@/views/trips/edit/dialogs/AddReloadStopDialog.vue";
import BaseView from "@/views/BaseView.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import { TravelOrderCancellationStatusMap, TravelOrderStatus } from "@/data/trip";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";
import RouteStopBlock from "@/views/trips/edit/blocks/RouteStopBlock.vue";
import OrderStopBlock from "@/views/trips/edit/blocks/OrderStopBlock.vue";
import TripInfo from "@/views/trips/edit/blocks/TripInfo.vue";
import { TripManager } from "@/views/trips/edit/trip-manager";
import { TripGrid } from "@/views/trips/edit/trip-grid";

export default {
  name: "TripEdit",
  components: {
    TripInfo,
    OrderStopBlock,
    RouteStopBlock,
    OmniDialog,
    AddReloadStopDialog,
    ReorderOrderStops,
    AddOrderDialog,
    RouteBlock,
    TravelOrderBlock,
    OrderBlock,
  },
  mixins: [BaseView, OrdersMixin],
  data() {
    return {
      orders: [],
      orderStops: [],
      routes: [],

      deliveryNum: 0,
      pickupNum: 0,

      addOrderDialog: false,
      reorderOrderStopsDialog: false,

      addReloadStopDialog: false,
      reloadStopIndex: 1,

      tripManager: null,
      tripGrid: null,
      trip: null,
      reloadOrderStop: null,
    };
  },
  computed: {
    orderFreights() {
      return this.tripManager.orders.flatMap((o) => o.freights);
    },
    saveEnabled() {
      return true;
      //return this.tripManager.routes?.every((r) => r.isDispatched === true);
    },
    cancelEnabled() {
      return this.$route.params.id && this.$route.params.id !== "new";
    },
    gridStyle() {
      return this.tripGrid.gridStyle;
    },
    stopsContainerStyle() {
      return this.tripGrid.stopsContainerStyle;
    },
  },
  async created() {
    this.tripManager = new TripManager(this.$api, this.$route);
    this.tripGrid = new TripGrid(this.tripManager);
    await this.tripManager.load();
    if (this.tripManager.shouldShowReorderStopsDialog) {
      this.showReorderStopsDialog();
    }
    this.trip = this.tripManager.trip;
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarSuccess", "showSnackbarError"]),
    generateStopName(stop) {
      stop.name = stop.type === this.STOP_PICK_UP ? "Pick up" : "Delivery";
      if (stop.isReload) stop.name += " reload stop";
      if (stop.num) stop.name += " #" + stop.num;

      return stop;
    },
    addOrder(order) {
      this.tripManager.addOrder(order);
      this.addOrderDialog = false;
      this.showReorderStopsDialog();
    },
    removeOrder(order) {
      // remove order from orders list
      const index = this.orders.indexOf(order);
      if (index !== -1) {
        this.orders.splice(index, 1);
      }

      // remove order stops
      this.orderStops = this.orderStops.filter((os) => os.orderId !== order.id);

      // remove route stops for deleted order
      for (const route of this.routes) {
        route.routeStops = route.routeStops.filter((rs) => rs.orderId !== order.id);
      }
    },
    numerateOrderStops() {
      let position = 1;
      let deliveryNum = 1;
      let pickupNum = 1;

      for (const orderStop of this.orderStops) {
        orderStop.position = position;
        position++;

        if (orderStop.type === this.STOP_DELIVERY) {
          orderStop.num = deliveryNum;
          deliveryNum++;
        }
        if (orderStop.type === this.STOP_PICK_UP) {
          orderStop.num = pickupNum;
          pickupNum++;
        }

        this.generateStopName(orderStop);
      }
    },
    showReorderStopsDialog() {
      this.reorderOrderStopsDialog = true;
    },
    closeReorderStopsDialog() {
      this.reorderOrderStopsDialog = false;
      this.tripManager.copyStopPositionsFromOrder();
    },
    showReloadStopDialog(stop, index) {
      this.reloadOrderStop = stop;
      this.addReloadStopDialog = true;
      this.reloadStopIndex = index;
    },
    addReloadStop(reloadStop, orderStop) {
      const index = this.reloadStopIndex + 1;
      reloadStop.position = index + 1;

      this.tripManager.addReloadStop(reloadStop, orderStop);
      this.addReloadStopDialog = false;
    },
    async save() {
      const item = { routes: this.tripManager.routes };
      // add trip id for redispatch and save exists trip
      if (this.$route.params.id !== "new") {
        item.id = this.$route.params.id;
      }

      const resp = await this.$api.trips.upsertTrip(item);
      if (resp.success) {
        this.toView(resp.data.id);
        this.showSnackbarSuccess(this.$t("general.saved"));
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    setTruck(route, truck) {
      if (truck) {
        route.truck = truck;
        route.truckId = truck.id;
      } else {
        route.truck = null;
        route.truckId = null;
      }
    },
    toView(id) {
      this.$router.push({ name: "trip-view", params: { id: id || this.$route.params.id } });
    },
    isRouteStopDisabled(route, stop) {
      if (!stop) {
        return false;
      }
      const travelOrder = route.travelOrders.find((to) => to.orderId === stop.order?.id);
      return travelOrder ? this.isTravelOrderCancelled(travelOrder) : false;
    },
    isTravelOrderCancelled(travelOrder) {
      return TravelOrderCancellationStatusMap.some((s) => s.id === travelOrder.status);
    },
    canChangeOrderStatus(travelOrder) {
      return this.routes
        .flatMap((r) => r.travelOrders.filter((to) => to.orderId === travelOrder.orderId && to.id !== travelOrder.id))
        .every((to) => TravelOrderCancellationStatusMap.some((s) => s.id === to.status));
    },
    showAddOrderDialog() {
      this.addOrderDialog = true;
    },
    addEmptyRoute() {
      const copyFreights = this.tripManager.routes.every((r) =>
        r.travelOrders.every((to) =>
          [TravelOrderStatus.cancelled, TravelOrderStatus.dryRun, TravelOrderStatus.waitingForDryRun].includes(
            to.status,
          ),
        ),
      );
      this.tripManager.addRoute(null, copyFreights);
    },
    onFreightsChanged(route, freights) {
      this.tripManager.mergeRouteFreights(route, freights);
    },
    getRouteStop(orderStop, route) {
      return route.routeStops.find((rs) => rs.orderStopId === orderStop.id);
    },
    getRouteReloadStops(orderStop, route) {
      return route.routeStops.filter((rs) => rs.orderStopId === orderStop.id);
    },
    changeRouteStop(orderStop, route, stop) {
      let routeStop = {};
      routeStop = route.routeStops.find((rs) => rs.tempId && rs.tempId === stop.tempId);
      if (!routeStop) {
        routeStop = route.routeStops.find((rs) => rs.id === stop.id);
      }
      Object.assign(routeStop, stop);
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-8 pb-10 pl-6 pr-6">
    <div class="pb-15">
      <div :style="gridStyle" class="stops-grid">
        <trip-info
          :orders="tripManager.orders"
          @add:order="showAddOrderDialog"
          @add:route="addEmptyRoute"
          @edit:stops-order="showReorderStopsDialog"
        ></trip-info>
        <order-block
          v-for="order in tripManager.orders"
          :key="order.id"
          :can-edit="tripManager.orders.length > 1"
          :order="order"
          class="mb-4"
          @remove="removeOrder"
        />
        <div
          :style="stopsContainerStyle"
          class="trips-block bg-white pa-4 pt-0 rounded-lg position-relative rounded-tl-0 rounded-tr-0 rounded-bl-0 rounded-br-0"
        >
          <div class="trip-block__header d-flex align-center justify-space-between my-2">
            <span class="text-grey-darken-2 font-weight-medium">Trip stops</span>
            <v-btn elevation="0" icon="mdi-dots-horizontal" size="x-small" @click="showReloadStopDialog = false" />
          </div>
          <template v-for="(stop, i) in tripManager.orderStops" :key="stop.id">
            <order-stop-block :order-freights="orderFreights" :routes="tripManager.routes" :stop="stop" />
            <div v-if="i < tripManager.orderStops.length - 1" class="plus-block text-center">
              <v-btn
                class="text-primary plus-btn"
                icon="mdi-plus"
                size="x-small"
                variant="flat"
                @click="() => showReloadStopDialog(stop, i)"
              />
            </div>
          </template>
        </div>
        <template v-for="(route, i) in tripManager.routes" :key="i">
          <route-block
            v-model="tripManager.routes[i]"
            :can-edit="tripManager.routes.length > 1"
            :orders="tripManager.orders"
            class="mb-4"
            @attach="(p) => setTruck(tripManager.routes[i], p.truck)"
            @remove-truck="setTruck(tripManager.routes[i], null)"
            @remove-route="tripManager.routes.splice(i, 1)"
          />

          <travel-order-block
            v-for="(travelOrder, j) in route.travelOrders"
            :key="j"
            v-model="route.travelOrders[j]"
            :can-change-order-status="canChangeOrderStatus(travelOrder)"
            :trip-id="$route.params.id"
            class="mb-4"
            @cancel:travel-order="(to) => tripManager.cancelTravelOrder(to)"
          />

          <div
            v-if="route.routeStops.length > 0"
            :style="stopsContainerStyle"
            class="trip-block bg-white pa-4 rounded-lg position-relative rounded-tl-0 rounded-tr-0 rounded-bl-0 rounded-br-0"
            data-qa="route-stops"
          >
            <div class="trip-block__header d-flex align-center justify-space-between mb-3">
              <span class="text-grey-darken-2 font-weight-medium">Route stops</span>
            </div>
            <template v-for="(orderStop, k) in tripManager.orderStops" :key="k">
              <div v-if="orderStop.isReload">
                <route-stop-block
                  v-for="reloadStop in getRouteReloadStops(orderStop, route)"
                  :key="reloadStop.id"
                  :order-freights="orderFreights"
                  :order-stops="tripManager.orderStops"
                  :route="route"
                  :route-stop="reloadStop"
                  @changed:stop="(stop) => changeRouteStop(orderStop, route, stop)"
                  @changed:freights="(freights) => onFreightsChanged(route, freights)"
                />
              </div>
              <route-stop-block
                v-else-if="getRouteStop(orderStop, route)"
                :allow-edit-usage="tripManager.routes.length > 1"
                :disabled="isRouteStopDisabled(route, getRouteStop(orderStop, route))"
                :is-reload="orderStop.isReload"
                :order-freights="orderFreights"
                :order-stops="tripManager.orderStops"
                :route="route"
                :route-stop="getRouteStop(orderStop, route)"
                @changed:stop="(stop) => changeRouteStop(orderStop, route, stop)"
                @changed:freights="(freights) => onFreightsChanged(route, freights)"
              />
              <div v-else></div>
              <!-- Empty div is necessary for correct grid work -->
              <div v-if="k < tripManager.orderStops.length - 1"></div>
            </template>
          </div>
        </template>
      </div>

      <div class="d-flex justify-end pa-5 bg-white user-info__buttons">
        <v-btn
          v-if="cancelEnabled"
          id="cancel-btn"
          class="pr-6 pl-6 font-weight-600 text-uppercase"
          color="primary"
          height="36"
          variant="text"
          @click="() => toView()"
        >
          Cancel
        </v-btn>
        <v-btn
          id="save-btn"
          :disabled="!saveEnabled"
          class="pr-6 pl-6 font-weight-600 text-uppercase"
          color="primary"
          height="36"
          variant="flat"
          @click="save"
        >
          <template v-if="!saveEnabled">Save</template>
          <template v-else>Save and dispatch</template>
        </v-btn>
      </div>
    </div>

    <add-order-dialog v-model="addOrderDialog" @change="addOrder" />

    <add-reload-stop-dialog
      v-model="addReloadStopDialog"
      :order-stops="tripManager.orderStops"
      :stop="reloadOrderStop"
      @add="addReloadStop"
    />

    <omni-dialog
      v-model="reorderOrderStopsDialog"
      primary-action-label="Save"
      title="Stops order"
      width="585"
      @primary-action:click="closeReorderStopsDialog"
    >
      <reorder-order-stops v-model="tripManager.orderStops" :orders="tripManager.orders" :routes="tripManager.routes" />
    </omni-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.stops-grid {
  display: grid;
  grid-auto-flow: column;
  column-gap: 24px;
  overflow-x: auto;
  width: 100%;
}

.trip-stops__block-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plus-block {
  line-height: 1;
}

.reload-stop {
  border: 2px dashed #556ee6;
  border-radius: 8px;
}
</style>
