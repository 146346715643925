<script lang="ts" setup>
import { computed, defineProps, useAttrs, withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    serverData?: boolean;
    showAllOption?: boolean;
    archived?: boolean;
    showArchive?: boolean;
    compact?: boolean;
  }>(),
  {
    serverData: true,
    showAllOption: true,
    archived: false,
    showArchive: false,
    compact: false,
  },
);

const emit = defineEmits<{
  (e: "update:archived", value: boolean): void;
}>();

defineOptions({
  inheritAttrs: false,
});

const slots = defineSlots<Record<any, (arg: any) => any>>();

const attrs = useAttrs();

const itemsLength = computed(() => (props.serverData ? attrs["items-length"] : (attrs["items"] as any[])?.length));

const archiveToggle = computed({
  get: () => (props.archived ? 1 : 0),
  set: (value: number) => {
    emit("update:archived", value === 1);
  },
});

const itemsPerPageOptions = computed(() => {
  const options = [10, 25, 50, 100];
  if (props.showAllOption) options.push(-1);
  return options;
});
</script>

<template>
  <v-card class="bg-transparent" variant="flat">
    <v-card-text class="pa-0">
      <div v-if="props.showArchive" class="d-flex mb-4 align-center justify-space-between">
        <div class="text-grey-darken-1 font-weight-500" data-qa="search-results">{{ itemsLength }} results found</div>
        <v-btn-toggle v-model="archiveToggle" class="archive__toggle" color="primary" variant="flat">
          <v-btn size="small">General</v-btn>
          <v-btn size="small">Archive</v-btn>
        </v-btn-toggle>
      </div>
      <v-data-table-server
        v-if="props.serverData"
        :class="{ compact: props.compact }"
        :hover="true"
        :items-length="itemsLength as number"
        :items-per-page-options="itemsPerPageOptions"
        class="omni-table"
        items-per-page-text="Rows per page:"
        v-bind="$attrs"
      >
        <template v-for="(_, name) in slots" #[name]="data">
          <slot :name="name" v-bind="data || {}"></slot>
        </template>
      </v-data-table-server>
      <v-data-table
        v-else
        :class="{ compact: props.compact }"
        :hover="true"
        :items-per-page-options="itemsPerPageOptions"
        class="omni-table"
        items-per-page-text="Rows per page:"
        v-bind="$attrs"
      >
        <template v-for="(_, name) in slots" #[name]="data">
          <slot :name="name" v-bind="data || {}"></slot>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
@import "@/assets/style/color.scss";

.archive__toggle {
  height: 24px !important;
  border: 1px solid #bdbdbd !important;
  color: #616161 !important;
  font-weight: 400 !important;
}

.omni-table {
  margin-bottom: 24px;

  .v-table__wrapper {
    overflow: visible;
  }

  .v-progress-linear__indeterminate {
    background-color: $primary;
  }

  .v-data-table-footer {
    position: fixed;
    z-index: 999;
    bottom: 0;
    width: calc(100% - 298px);
    right: 24px;
    border-top: 0;
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 1px 5px rgba(0, 0, 0, 0.12);
    background: #fff;
    padding: 4px 16px;
    justify-content: flex-start;
    height: 56px;

    .v-data-table-footer__items-per-page {
      margin-left: 0;
      font-size: 16px;
      color: #424242;

      & > span {
        padding-inline-end: 16px;
      }

      .v-input {
        margin: 0 0 0 10px;

        .v-select__selection {
          font-size: 16px;
          color: #212121;
          align-items: center;
        }
      }
    }

    .v-input__slot {
      &:before,
      &:after {
        display: none;
      }
    }

    .v-input__append-inner {
      padding-left: 0;
      margin-top: 3px;

      .v-icon {
        color: #757575;
      }
    }

    .v-data-table-footer__pagination {
      margin-left: 0;
      font-size: 16px;
      color: #424242;

      .v-pagination__first,
      .v-pagination__last {
        display: none;
      }
    }

    .v-data-table-footer__info {
      font-size: 16px;
      color: #424242;
    }

    .v-pagination__prev,
    .v-pagination__next {
      .v-btn {
        width: 24px;
        height: 24px;
        background: #f5f5f5;
        border-radius: 4px;
        color: #1c1b1f;
      }
    }

    .v-data-footer__icons-before .v-btn:last-child {
      margin-right: 4px;
    }

    .v-data-footer__icons-after .v-btn:first-child {
      margin-left: 4px;
    }
  }

  &.compact {
    padding-bottom: 32px;

    .v-data-table-footer {
      position: absolute;
      right: 0;
      width: 100%;
    }
  }

  table {
    border: 0 !important;
    border-left: 1px solid #e0e0e0 !important;
    border-right: 1px solid #e0e0e0 !important;
  }

  thead {
    tr {
      background: transparent !important;
    }

    th {
      position: relative;
      height: 40px !important;
      color: #757575 !important;
      font-weight: 600 !important;

      &:not(:last-child) {
        border-right: 1px solid #e0e0e0;
      }

      &:hover,
      &.active {
        color: #424242 !important;

        .v-data-table-header__icon {
          color: #424242 !important;
        }
      }

      .v-data-table-header__icon {
        position: absolute;
        right: 16px;
        opacity: 1;
        color: #757575 !important;
      }
    }
  }

  tbody {
    td {
      position: relative;
      height: 56px !important;
      border-bottom: 1px solid #e0e0e0;
      padding-top: 7px !important;
      padding-bottom: 7px !important;

      &:not(:last-child) {
        border-right: 1px solid #e0e0e0;
      }

      .status-items {
        position: absolute;
        right: 4px;
        top: 4px;
        line-height: 1;
        width: calc(100% - 8px);

        .status-item:not(:last-child) {
          margin-right: 2px;
        }
      }

      .name {
        display: block;
      }
    }
  }

  .v-data-table-header__content {
    justify-content: space-between;
  }
}
</style>
