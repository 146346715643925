<script lang="ts" setup>
import type { BrokerUpdatesOrderDto, TravelOrderDto } from "@/api/trips/dto/trip.dto";
import { API } from "@/api/api";
import type { PreviewUpdateRequest, PreviewUpdateResponse, TripUpdate } from "@/api/trips/dto/update";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { FilterUtils } from "@/filters";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import type { CheckCallDTO, CheckCallWithUpdate } from "@/api/trips/dto/check-call";
import CheckCallDateTime from "@/views/trips/view/components/CheckCallDateTime.vue";
import OmniTable from "@/components/OmniTable.vue";
import { useSnackbarStore } from "@/store/snackbar.store";
import CreateNewUpdateDialog from "@/views/trips/view/dialogs/CreateNewUpdateDialog.vue";

const tableHeaders = [
  { title: "Type", key: "type", sortable: false },
  { title: "Location", key: "locationAddress", class: "text-grey", sortable: false },
  { title: "Broker update", key: "brokerUpdate", sortable: false },
  { title: "Date time", key: "dateTime", sortable: false },
  { title: "Actions", key: "actions", sortable: false, align: "center", width: 178 },
];

const api = new API();
const filters = new FilterUtils();
const dateUtils = DateTimeMixin.methods!;

const location = useRoute();
const router = useRouter();
const { showSnackbarError } = useSnackbarStore();

const props = defineProps<{
  orders: BrokerUpdatesOrderDto[];
}>();

const checkCalls = ref<CheckCallWithUpdate[]>([]);
const showCreateUpdate = ref<boolean>(false);
const createNewUpdatePreview = ref<PreviewUpdateResponse>({
  subject: "",
  message: "",
  messageId: "",
  recipients: [],
});

const selectedTravelOrder = ref<TravelOrderDto | null>(null);
const selectedCheckCall = ref<CheckCallDTO | null>(null);

const currentOrder = computed(() => {
  return props.orders.find(({ id }) => id === location.params.orderId);
});

const tripId = computed(() => {
  return location.params.id.toString();
});

async function loadCheckCalls(): Promise<void> {
  try {
    const response = await api.trips.getCheckCalls(tripId.value);

    if (response.success) {
      checkCalls.value = response.data?.checkCalls || [];
    }
  } catch (error) {
    throw new Error("Could not load check calls");
  }
}

async function loadUpdates(): Promise<void> {
  try {
    const response = await api.trips.getUpdates(tripId.value);
    const updates = response.data?.updates || [];

    checkCalls.value = checkCalls.value.map((checkCall) => {
      const update = getUpdateForCheckCall(updates, checkCall);

      return {
        ...checkCall,
        update: update,
      };
    });
  } catch (error) {
    throw new Error("Could not load broker updates");
  }
}

function getRelatedCheckCalls(travelOrder: TravelOrderDto): CheckCallWithUpdate[] {
  return checkCalls.value
    .filter((checkCall) => {
      const isRouteIdMatched = travelOrder.routeId === checkCall.routeId;

      if (!isRouteIdMatched) {
        return;
      }

      const travelOrderIds = checkCall.travelOrderIds;

      if (!travelOrderIds?.length) {
        return true;
      }

      return travelOrder.id && travelOrderIds.includes(travelOrder.id);
    })
    .reverse();
}

function getUpdateForCheckCall(updates: TripUpdate[], checkCall: CheckCallWithUpdate): TripUpdate | undefined {
  if (checkCall.type === "check-call") {
    return updates.find((update) => update.checkCallId === checkCall.id);
  } else {
    return updates.find(
      (update) => update.checkCallId === checkCall.id || checkCall.travelOrderIds?.includes(update.travelOrderId),
    );
  }
}

function getTime(createAt: string, checkCallTz: string) {
  return dateUtils.dateTZ(createAt || "", checkCallTz, "HH:mm");
}

async function openCreateUpdate(travelOrder: TravelOrderDto, checkCall: CheckCallDTO) {
  try {
    const routeId = travelOrder.routeId;
    const travelId = travelOrder.id;

    if (!routeId || !travelId) {
      return;
    }

    const request: PreviewUpdateRequest = {
      checkCallId: checkCall.id,
      travelOrderId: travelId,
    };

    const response = await api.events.previewUpdate(routeId, request);

    if (response.success && response.data) {
      showCreateUpdate.value = true;
      selectedTravelOrder.value = travelOrder;
      selectedCheckCall.value = checkCall;
      createNewUpdatePreview.value = response.data;
    }
  } catch (error) {
    showSnackbarError("Could not load update preview");
  }
}

function goToTruckForm(truckNumber?: string) {
  if (!truckNumber) {
    return;
  }

  window.open(router.resolve({ name: "truck-view", params: { id: truckNumber } }).href, "_blank");
}

async function init() {
  await loadCheckCalls();
  await loadUpdates();
}

onMounted(async () => {
  await init();
});
</script>

<template>
  <div v-for="travelOrder of currentOrder?.travelOrders" :key="travelOrder.id" class="mt-11">
    <div class="d-flex align-center">
      <div class="font-size-16 font-weight-500 text-gray-darken-3 mr-4">Travel order #{{ travelOrder.number }}</div>

      <div class="cursor-pointer" role="button" tabindex="0" @click="goToTruckForm(travelOrder.truckNumber)">
        <v-icon class="mr-1" color="primary" size="14">mdi-truck-outline</v-icon>
        <span class="font-size-12 font-weight-500 text-primary">Truck: #{{ travelOrder.truckNumber }}</span>
      </div>
    </div>

    <omni-table :headers="tableHeaders" :items="getRelatedCheckCalls(travelOrder)" :server-data="false" class="mt-5">
      <template #[`header.locationAddress`]="{ column }: { column?: { title?: string | undefined } }">
        <div class="py-2">
          {{ column?.title }},
          <div>Miles left to next stop</div>
        </div>
      </template>

      <template #[`item.locationAddress`]="{ item }: { item: CheckCallWithUpdate }">
        {{ item.locationAddress }}
        <div class="font-size-12 text-grey-darken-1">
          Miles left to next stop: {{ filters.miles(item.nextStopDistance, 0) }}
        </div>
      </template>

      <template #[`item.dateTime`]="{ item }: { item: CheckCallWithUpdate }">
        <check-call-date-time :check-call="item" />
      </template>

      <template #[`item.brokerUpdate`]="{ item }: { item: CheckCallWithUpdate }">
        {{ item.update?.message }}
      </template>

      <template #[`item.actions`]="{ item }: { item: CheckCallWithUpdate }">
        <div v-if="item.update" class="d-flex align-center justify-space-between">
          <div class="font-weight-500 font-size-12 mr-2">
            Sent by <span class="text-primary">{{ item.update?.createdDispatcher.name }}</span>
            <span class="text-grey-darken-1"> at {{ getTime(item.update?.createdAt, item.timezone) }}</span>
          </div>

          <v-icon color="primary">mdi-eye-outline</v-icon>
        </div>
        <v-btn
          v-else
          class="text-uppercase"
          color="primary"
          variant="flat"
          @click="openCreateUpdate(travelOrder, item)"
        >
          Create update
        </v-btn>
      </template>
      <template #bottom>
        <div class="d-flex justify-space-between bottom-row"></div>
      </template>
    </omni-table>
  </div>

  <template v-if="selectedTravelOrder && selectedCheckCall">
    <create-new-update-dialog
      v-model="showCreateUpdate"
      :check-call="selectedCheckCall"
      :preview-info="createNewUpdatePreview"
      :travel-order="selectedTravelOrder"
      @update-table="init"
    />
  </template>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color.scss";

.bottom-row {
  height: 100px;
  background-color: $primary-light;
  border: 1px solid $grey-lighter;
}
</style>
