<script lang="ts" setup>
import OmniDialog from "@/components/OmniDialog.vue";
import { computed, ref, useTemplateRef, watch } from "vue";
import { API } from "@/api/api";
import {
  type CheckCallDTO,
  type CheckCallInfoDTO,
  type CheckCallRouteStopDTO,
  CheckCallType,
  type CreateCheckCallRequest,
  DeviationStatus,
} from "@/api/trips/dto/check-call";
import { GoogleMap, Marker as GMarker, Polyline as GPolyline } from "vue3-google-map";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import {
  CheckCallMarker,
  EndPointMarker,
  NewPointMarker,
  PathArrowReverse,
  StartDispatchPointMarker,
  StartPointMarker,
} from "@/views/trucks/history/markers";
import { OrderStopType } from "@/data/order";
import { decode } from "@googlemaps/polyline-codec";
// @ts-ignore
import LocationPicker from "@/components/pickers/LocationPicker.vue";
// @ts-ignore
import DateTimePicker from "@/components/pickers/DateTimePicker.vue";
import { FilterUtils } from "@/filters";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { type AppStateDispatcher, useAppStore } from "@/store/app.store";
import { DialogSize } from "@/components/types";
import DeviationStatusLabel from "@/views/trips/components/DeviationStatusLabel.vue";

const api = new API();
const mapUtils = GoogleMapsMixin.methods;
const filters = new FilterUtils();
const dateUtils = DateTimeMixin.methods!;

const appStore = useAppStore();

const props = defineProps<{
  modelValue: boolean;
  routeId: number;
}>();

const emit = defineEmits<{
  (e: "change"): void;
}>();

const checkCallInfo = ref<CheckCallInfoDTO>();
const preview = ref<boolean>(false);
const loading = ref<boolean>(false);
const location = ref();
const date = ref();
const checkCallPreview = ref<CheckCallDTO>();
const mapCenter = ref();
const mapZoom = ref();

const mapRef = useTemplateRef<any>("map");

const showDialog = defineModel<boolean>({ default: false });
const primaryActionLabel = computed<string>(() => (preview.value ? "Create" : "Preview"));
const lineSymbol = {
  path: "M0-1V1Z",
  strokeColor: "#4CAF50",
  scale: 6,
  strokeOpacity: 1,
};
const routeLineOptions = computed(() => {
  const path = checkCallInfo.value?.routePolyline
    ? decode(checkCallInfo.value!.routePolyline!).map((tuple) => ({
        lat: tuple[0],
        lng: tuple[1],
      }))
    : null;
  return {
    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "24px",
      },
      {
        icon: PathArrowReverse,
        repeat: "24px",
        offset: "0",
      },
    ],
    path,
  };
});

const checkCallsLineOptions = computed(() => {
  const path = checkCallInfo.value?.checkCallsPolyline
    ? decode(checkCallInfo.value!.checkCallsPolyline!).map((tuple) => ({
        lat: tuple[0],
        lng: tuple[1],
      }))
    : null;
  return {
    icons: [
      {
        icon: PathArrowReverse,
        offset: 0,
        repeat: "50px",
      },
    ],
    strokeWeight: 6,
    strokeColor: "#304FFE",
    path,
  };
});

const checkCallMarkers = computed(() => {
  return checkCallInfo.value?.checkCalls
    .filter((cc) => cc.type === CheckCallType.checkCall)
    .map((cc) => ({
      id: cc.id,
      position: {
        lat: cc.locationCoordinates.lat,
        lng: cc.locationCoordinates.lon,
      },
      icon: CheckCallMarker,
    }));
});

const startDispatchMarker = computed(() => {
  const startDispatch = checkCallInfo.value?.checkCalls.find((cc) => cc.type === CheckCallType.dispatchConfirmed);
  return (
    startDispatch && {
      id: startDispatch.id,
      position: {
        lat: startDispatch.locationCoordinates.lat,
        lng: startDispatch.locationCoordinates.lon,
      },
      icon: StartDispatchPointMarker,
      label: {
        text: "Start",
        className: "map__label start-dispatch",
      },
    }
  );
});

const pickerTimeLabel = computed(() => {
  const checkCallDate = date.value;
  const dispatcherTimezone = (appStore.dispatcher as AppStateDispatcher)?.timezone;
  const locationTimezone = location.value?.timezone;
  let label = "Local time";
  if (checkCallDate && dispatcherTimezone && locationTimezone && dispatcherTimezone !== locationTimezone) {
    const timezoneName = dateUtils.getShortTZForDate(new Date(), dispatcherTimezone);
    const dispatcherTimezoneDate = dateUtils.fromTZToTZ(checkCallDate, locationTimezone, dispatcherTimezone, "HH:mm");
    return label + " / " + timezoneName + ": " + dispatcherTimezoneDate;
  }
  return label;
});

watch(showDialog, async (value) => {
  if (value) {
    loading.value = true;
    date.value = null;
    location.value = null;
    preview.value = false;
    checkCallPreview.value = undefined;
    const response = await api.events.getCheckCallInfo(props.routeId);
    if (response.success) {
      checkCallInfo.value = response.data;
      setMapCenter(checkCallInfo.value!);
    }
    loading.value = false;
  }
});

watch(location, (value) => {
  if (value) {
    mapRef.value.map.panTo({
      lat: value.lat,
      lng: value.lng,
    });
    mapRef.value.map.setZoom(8);
  }
});

async function onPrimaryAction() {
  const request: CreateCheckCallRequest = {
    checkCallTime: date.value,
    locationAddress: location.value.fullAddress,
    locationCoordinates: {
      lat: location.value.lat,
      lon: location.value.lng,
    },
  };
  loading.value = true;
  if (preview.value) {
    await api.events.createCheckCall(props.routeId, request);
    emit("change");
    showDialog.value = false;
  } else {
    const response = await api.events.previewCheckCall(props.routeId, request);
    if (response.success) {
      checkCallPreview.value = response.data;
    }
    preview.value = true;
  }
  loading.value = false;
}

function getFacilityMarker(stop: CheckCallRouteStopDTO) {
  return {
    position: {
      lat: stop.facilityLocation.lat,
      lng: stop.facilityLocation.lon,
    },
    label:
      stop.type === OrderStopType.pickup
        ? {
            text: "Pick up",
            className: "map__label start",
          }
        : {
            text: "Delivery",
            className: "map__label end",
          },
    icon: stop.type === OrderStopType.pickup ? StartPointMarker : EndPointMarker,
  };
}

function copyLocation() {
  location.value = {
    fullAddress: checkCallInfo.value?.truck.currentAddress,
    lat: checkCallInfo.value?.truck.currentLocation?.lat,
    lng: checkCallInfo.value?.truck.currentLocation?.lon,
    timezone: checkCallInfo.value?.truck.currentLocationTimezone,
  };
  date.value = dateUtils.nowTZ(location.value.timezone);
}

function getDeviationStatusClass(deviationStatus: DeviationStatus): string {
  switch (deviationStatus) {
    case DeviationStatus.onTrack:
      return "text-success";
    case DeviationStatus.deviated:
      return "text-warning";
    case DeviationStatus.wrongWay:
      return "text-error";
    default:
      return "";
  }
}

function setMapCenter(checkCallInfo: CheckCallInfoDTO) {
  const coordinates = [...decode(checkCallInfo.routePolyline || ""), ...decode(checkCallInfo.checkCallsPolyline || "")];
  const { minLat, maxLat, minLon, maxLon } = coordinates.reduce(
    (acc, [lat, lon]) => ({
      minLat: Math.min(acc.minLat, lat),
      maxLat: Math.max(acc.maxLat, lat),
      minLon: Math.min(acc.minLon, lon),
      maxLon: Math.max(acc.maxLon, lon),
    }),
    {
      minLat: Infinity,
      maxLat: -Infinity,
      minLon: Infinity,
      maxLon: -Infinity,
    },
  );

  mapCenter.value = { lat: (minLat + maxLat) / 2, lng: (minLon + maxLon) / 2 };
  mapZoom.value = 6;
}

function onEditAction() {
  preview.value = false;
}
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    :disabled="loading"
    :loading="loading"
    :primary-action-label="primaryActionLabel"
    :size="DialogSize.xxlarge"
    :title="'New check call for truck #' + checkCallInfo?.truck.number"
    @primary-action:click="onPrimaryAction"
  >
    <transition mode="out-in">
      <div v-if="!preview">
        <div class="d-flex align-center mb-6">
          <div class="bg-grey-lighten-4 rounded pa-3 w-100 mr-3">
            <div class="text-grey-darken-1 font-size-12 mb-1">Last known location</div>
            <div v-if="checkCallInfo?.truck" class="d-flex align-center mb-1">
              <span class="text-grey-darken-4">
                {{ checkCallInfo.truck.currentAddress }}
              </span>
              <router-link
                :to="mapUtils.getPlaceUrl(checkCallInfo.truck.currentLocation!)"
                class="ml-2"
                target="_blank"
              >
                <v-icon
                  class="text-green text--green-accent-2"
                  icon="mdi-map-marker-radius-outline"
                  size="18"
                  v-bind="props"
                ></v-icon>
              </router-link>
            </div>
            <div class="text-grey-darken-2 font-size-12 font-weight-500">
              {{
                dateUtils.dateTZ(checkCallInfo?.truck.currentLocationTime || "", appStore.dispatcher?.timezone || "")
              }}
            </div>
            <div class="text-grey-darken-1 font-size-12">
              {{
                dateUtils.dateTZ(
                  checkCallInfo?.truck.currentLocationTime || "",
                  checkCallInfo?.truck.currentLocationTimezone || "",
                )
              }}
            </div>
          </div>
          <v-btn color="primary" density="compact" icon="mdi-arrow-down" variant="text" @click="copyLocation"></v-btn>
        </div>
        <location-picker v-model="location" class="mb-6" required></location-picker>
        <date-time-picker v-model="date" :time-label="pickerTimeLabel" class="mb-6" required></date-time-picker>
      </div>
      <div v-else class="bg-grey-lighten-4 rounded mb-6 pa-3">
        <div class="text-grey-darken-1 font-size-12 mb-4">Current check call</div>
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-grey-darken-2 mb-2">Location:</div>
            <div class="mb-6 d-flex align-center">
              <span class="text-grey-darken-3 font-weight-500" data-qa="check-call-modal-location">
                {{ checkCallPreview?.locationAddress }}
              </span>
              <v-icon class="text-green ml-2" icon="mdi-map-marker-radius-outline" size="18"></v-icon>
            </div>
            <div class="text-grey-darken-2 mb-2">Check call time:</div>
            <div class="mb-6">
              <div class="text-grey-darken-3 font-weight-500" data-qa="check-call-modal-time-1">
                {{ dateUtils.dateTZ(checkCallPreview!.checkCallTime, appStore.dispatcher!.timezone) }}
              </div>
              <div class="text-grey-darken-1" data-qa="check-call-modal-time-2">
                {{ dateUtils.dateTZ(checkCallPreview!.checkCallTime, checkCallPreview!.timezone) }}
              </div>
            </div>
            <div class="text-grey-darken-2 mb-2">Miles left to:</div>
            <div class="text-grey-darken-3 font-weight-500">
              <template v-if="checkCallPreview!.nextStopDistance">
                <span class="text-grey-darken-3 font-weight-500" data-qa="check-call-modal-miles-left-total"
                  >{{ filters.miles(checkCallPreview!.nextStopDistance, 0) }} miles</span
                >
                <span
                  :class="{
                    'text-success': checkCallPreview!.distanceReducing! < 0,
                    'text-error': checkCallPreview!.distanceReducing! >= 0,
                  }"
                  class="ml-2 font-weight-500" data-qa="check-call-modal-miles-left-extra"
                >
                  {{ filters.miles(checkCallPreview!.distanceReducing, 0) }} miles
                </span>
              </template>
              <template v-else> -</template>
            </div>
          </div>
          <div>
            <div class="text-grey-darken-2 mb-2">From last check call:</div>
            <div class="text-grey-darken-3 font-weight-500 mb-6">
              <template v-if="checkCallPreview!.lastCallDistance">
                <span class="text-grey-darken-3 font-weight-500" data-qa="check-call-modal-from-last-cc-total">
                  {{ filters.miles(checkCallPreview!.lastCallDistance, 0) }} miles
                </span>
                <span
                  :class="getDeviationStatusClass(checkCallPreview!.currentDeviationStatus!)"
                  class="font-weight-500" data-qa="check-call-modal-from-last-cc-extra"
                >
                  ({{ filters.miles(checkCallPreview!.currentDeviation, 0) }})
                </span>
                <deviation-status-label
                  :deviation-status="checkCallPreview!.currentDeviationStatus!"
                  class="ml-3"
                  data-qa="check-call-modal-from-last-cc-status"
                ></deviation-status-label>
              </template>
              <template v-else> -</template>
            </div>
            <div class="text-grey-darken-2 mb-2">Next stop time:</div>
            <div class="mb-6">
              <div class="text-grey-darken-3 font-weight-500" data-qa="check-call-modal-next-stop-time-1">
                {{
                  checkCallInfo?.nextRouteStop?.eta
                    ? dateUtils.dateTZ(checkCallInfo!.nextRouteStop!.eta, appStore.dispatcher!.timezone)
                    : "-"
                }}
              </div>
              <div class="text-grey-darken-1" data-qa="check-call-modal-next-stop-time-2">
                {{
                  checkCallInfo?.nextRouteStop?.eta
                    ? dateUtils.dateTZ(checkCallInfo!.nextRouteStop!.eta, checkCallPreview!.timezone)
                    : "-"
                }}
              </div>
            </div>
            <div class="text-grey-darken-2 mb-2">From last stop:</div>
            <div class="text-grey-darken-3 font-weight-500">
              <template v-if="checkCallPreview!.lastStopDistance">
                <span class="text-grey-darken-3 font-weight-500" data-qa="check-call-modal-from-last-stop-total">
                  {{ filters.miles(checkCallPreview!.lastStopDistance, 0) }} miles
                </span>
                <span
                  :class="getDeviationStatusClass(checkCallPreview!.accumulatedDeviationStatus!)"
                  class="font-weight-500" data-qa="check-call-modal-from-last-stop-extra"
                >
                  ({{ filters.miles(checkCallPreview!.accumulatedDeviation, 0) }})
                </span>
                <deviation-status-label
                  :deviation-status="checkCallPreview!.accumulatedDeviationStatus!"
                  class="ml-3" data-qa="check-call-modal-from-last-stop-status"
                ></deviation-status-label>
              </template>
              <template v-else> -</template>
            </div>
          </div>
          <div>
            <div class="text-grey-darken-2 mb-2">Avg speed:</div>
            <div class="text-grey-darken-3 font-weight-500 mb-6" data-qa="check-call-modal-avg-speed">
              {{ filters.miles(checkCallPreview!.avgSpeed, 0) }}
              miles/hour
            </div>
            <div class="text-grey-darken-2 mb-2">ETA:</div>
            <div class="border-success rounded py-1 px-2 text-success font-weight-500">
              <div data-qa="check-call-modal-eta-time-1">
                {{ dateUtils.dateTZ(checkCallPreview!.eta!, appStore.dispatcher!.timezone) }}
                </div>
              <div data-qa="check-call-modal-eta-time-2">
                {{ dateUtils.dateTZ(checkCallPreview!.eta!, checkCallPreview!.timezone) }}
                </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <google-map
      ref="map"
      :api-key="mapUtils.getApiKey()"
      :center="mapCenter"
      :fullscreen-control="false"
      :map-type-control="false"
      :street-view-control="false"
      :zoom="4"
      style="width: 100%; height: 500px"
    >
      <g-polyline :options="routeLineOptions"></g-polyline>
      <g-polyline :options="checkCallsLineOptions"></g-polyline>
      <g-marker v-for="stop in checkCallInfo?.routeStops" :key="stop.id" :options="getFacilityMarker(stop)"></g-marker>
      <g-marker
        v-for="checkCallMarker in checkCallMarkers || []"
        :key="checkCallMarker.id"
        :options="checkCallMarker"
      ></g-marker>
      <g-marker v-if="startDispatchMarker" :options="startDispatchMarker"></g-marker>
      <g-marker
        v-if="location"
        :options="{ position: { lat: location.lat, lng: location.lng }, icon: NewPointMarker }"
      ></g-marker>
    </google-map>
    <template #actions:custom>
      <v-btn
        v-if="preview"
        :disabled="loading"
        class="text-uppercase font-weight-600 font-size-14 px-4"
        color="primary"
        variant="outlined"
        @click="onEditAction"
      >
        Edit
      </v-btn>
    </template>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
