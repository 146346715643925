import type { TripManager } from "@/views/trips/edit/trip-manager";

export class TripGrid {
  private tripManager: TripManager;

  private tripBlocksCount = 1;
  private stopsTitleBlocksCount = 1;

  constructor(tripManager: TripManager) {
    this.tripManager = tripManager;
  }

  get gridStyle() {
    const rows =
      this.tripBlocksCount +
      this.tripManager.orders.length +
      this.stopsTitleBlocksCount +
      (this.tripManager.orderStops.length * 2 - 1);
    return {
      gridTemplateRows: `repeat(${rows}, auto)`,
      gridAutoColumns: `minmax(400px, 600px)`,
    };
  }

  get stopsContainerStyle() {
    const lineStart = this.tripManager.orders.length + this.tripBlocksCount + 1;
    const lineEnd = lineStart + this.tripManager.orderStops.length * 2;
    return {
      gridRow: `${lineStart}/${lineEnd}`,
      display: "grid",
      gridTemplateRows: "subgrid",
    };
  }
}
