import { OrderStopType } from "@/data/order";

export enum RouteStopStatus {
  notStarted = "new",
  inRoute = "in-route",
  waitingForLoading = "wait-load-unload",
  waitingForVerification = "wait-verify",
  waitingForGoodToGo = "wait-go",
  completed = "completed",
}

export const RouteStopStatusMap = [
  {
    id: RouteStopStatus.notStarted,
    name: "Not started",
  },
  {
    id: RouteStopStatus.inRoute,
    name: "On route to",
  },
  {
    id: RouteStopStatus.waitingForLoading,
    name: "Waiting for loading",
    type: OrderStopType.pickup,
  },
  {
    id: RouteStopStatus.waitingForLoading,
    name: "Waiting for unloading",
    type: OrderStopType.delivery,
  },
  {
    id: RouteStopStatus.waitingForVerification,
    name: "Waiting for verification",
  },
  {
    id: RouteStopStatus.waitingForGoodToGo,
    name: "Waiting for good to go",
  },
  {
    id: RouteStopStatus.completed,
    name: "Completed",
  },
];

export enum TravelOrderStatus {
  notStarted = "new",
  inProgress = "in-progress",
  dryRun = "dry-run",
  waitingForDryRun = "wait-dry-run",
  noDryRun = "no-dry-run",
  completed = "completed",
  cancelled = "cancelled",
}

export const TravelOrderStatusMap = [
  {
    id: TravelOrderStatus.notStarted,
    name: "Not started",
  },
  {
    id: TravelOrderStatus.inProgress,
    name: "In progress",
  },
  {
    id: TravelOrderStatus.dryRun,
    name: "Dry run",
    forCancellation: true,
  },
  {
    id: TravelOrderStatus.waitingForDryRun,
    name: "Waiting for dry run",
    forCancellation: true,
  },
  {
    id: TravelOrderStatus.noDryRun,
    name: "No dry run",
  },
  {
    id: TravelOrderStatus.completed,
    name: "Completed",
  },
  {
    id: TravelOrderStatus.cancelled,
    name: "Cancelled",
    forCancellation: true,
  },
];

export const TravelOrderCancellationStatusMap = TravelOrderStatusMap.filter((s) => s.forCancellation);

export enum RouteStatus {
  notStarted = "new",
  inProgress = "in-progress",
  completed = "completed",
}

export const RouteStatusMap = [
  {
    id: RouteStatus.notStarted,
    name: "New",
  },
  {
    id: RouteStatus.inProgress,
    name: "In progress",
  },
  {
    id: RouteStatus.completed,
    name: "Completed",
  },
];

export enum TripStatus {
  notStarted = "new",
  inProgress = "in-progress",
  completed = "completed",
}

export const TripStatusMap = [
  {
    id: TripStatus.notStarted,
    name: "New",
  },
  {
    id: TripStatus.inProgress,
    name: "In progress",
  },
  {
    id: TripStatus.completed,
    name: "Completed",
  },
];
