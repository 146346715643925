<script lang="ts" setup>
import type { BrokerUpdatesOrderDto, TravelOrderIdsByTruckName, TripDto } from "@/api/trips/dto/trip.dto";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { API } from "@/api/api";

const api = new API();

const location = useRoute();
const router = useRouter();

const props = defineProps<{
  trip: TripDto;
}>();

const uniqueOrders = ref<BrokerUpdatesOrderDto[]>([]);
const travelOrdersByTruckName = ref<TravelOrderIdsByTruckName>({});

async function createTravelOrdersByTruckName() {
  const entries = await Promise.all(
    props.trip.routes.map(async (route) => {
      const truckName = route.truckId ? await loadTruckById(route.truckId) : "";
      return [truckName, route.travelOrders.map(({ id }) => id)];
    }),
  );

  travelOrdersByTruckName.value = Object.fromEntries(entries);
}

function createUniqueOrders() {
  if (!props.trip?.routes) {
    uniqueOrders.value = [];
    return;
  }

  const ordersMap: Record<string, BrokerUpdatesOrderDto> = {};

  props.trip.routes.forEach((route) => {
    route.travelOrders.forEach((travelOrder) => {
      const orderId = travelOrder.order.id;
      const truckNumber = getTruckNameById(travelOrder.id);

      if (!ordersMap[orderId]) {
        ordersMap[orderId] = {
          ...travelOrder.order,
          travelOrders: [],
        };
      }

      ordersMap[orderId].travelOrders.push({
        ...travelOrder,
        truckNumber,
        routeId: route.id,
      });
    });
  });

  uniqueOrders.value = Object.values(ordersMap);
}

async function loadTruckById(id: number): Promise<string> {
  try {
    const response = await api.trucks.findTruckById(id);
    return response?.data?.number || "";
  } catch (error) {
    throw new Error("Truck not found");
  }
}

function getTruckNameById(id: number | undefined): string {
  return Object.entries(travelOrdersByTruckName.value).find(([, values]) => values.includes(id))?.[0] || "";
}

watch(
  () => props.trip,
  async (value) => {
    await createTravelOrdersByTruckName();
    createUniqueOrders();

    if (!location.params.orderId && value) {
      await router.push({
        name: "trip-broker-updates-order",
        params: { id: props.trip.id, orderId: uniqueOrders.value[0].id },
      });
    }
  },
);
</script>

<template>
  <div class="my-4">
    <v-btn
      v-for="order in uniqueOrders"
      :key="order.id"
      :to="{ name: 'trip-broker-updates-order', params: { id: trip.id, orderId: order.id } }"
      active-color="primary"
      class="text-uppercase"
      variant="flat"
    >
      Order #{{ order.number }}
    </v-btn>

    <router-view v-slot="{ Component }">
      <component :is="Component" :orders="uniqueOrders" />
    </router-view>
  </div>
</template>

<style lang="scss" scoped></style>
