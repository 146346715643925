<script>
import FacilitySelect from "@/views/orders/components/FacilitySelect.vue";
import { OrdersMixin } from "@/views/orders/orders.mixin";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import { customAlphabet } from "nanoid";
import { OrderStopStatus, OrderStopTimeType, OrderStopType } from "@/data/order";

export default {
  name: "AddReloadStopDialog",
  components: { OmniDialog, OmniTextarea, FacilitySelect },
  mixins: [OrdersMixin],
  props: {
    modelValue: Boolean,
    stop: null,
    orderStops: Array,
  },
  emits: ["update:modelValue", "add"],
  data() {
    return {
      facilityId: null,
      note: "",
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    async add() {
      const facility = (await this.$api.company.findFacilityById(this.facilityId)).data;
      const stop = {
        id: this.generateId(),
        type: OrderStopType.reload,
        status: OrderStopStatus.notStarted,
        isReload: true,
        editable: true,
        name: "Reload stop",
        use: true,
        timeType: OrderStopTimeType.direct,
        timeTo: this.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
        facility,
        facilityId: facility.id,
        timezone: facility.timezone,
        note: this.note,
        order: this.stop.order,
        freights: this.undeliveredFreights(),
      };
      this.$emit("add", stop, this.stop);
    },
    undeliveredFreights() {
      let deliveredFreights = [];
      let remainingFreights = [];

      for (let stop of this.orderStops) {
        for (let freight of stop.freights) {
          if (stop.type === OrderStopType.pickup) {
            remainingFreights.push(freight);
          } else if (stop.type === OrderStopType.delivery) {
            deliveredFreights.push(freight);
          }
        }

        // If we reached the given stop, break out of the loop
        if (stop.id === this.stop.id) {
          break;
        }
      }

      // Filter freights in the given stop that are not delivered yet
      return remainingFreights.filter((freight) => !deliveredFreights.includes(freight));
    },
    generateId() {
      const nanoid = customAlphabet("1234567890abcdef", 16);
      return nanoid();
    },
  },
};
</script>
<template>
  <omni-dialog
    v-model="showDialog"
    primary-action-label="Add"
    title="Add reload stop"
    width="585"
    @primary-action:click="add"
  >
    <div class="py-4">
      <facility-select v-model="facilityId" class="mb-3" editable />
      <omni-textarea v-model="note" class="mt-3" hide-details label="Reload stop note" rows="3" />
    </div>
  </omni-dialog>
</template>
