<script>
import ValidationMixin from "@/mixins/validation.mixin";

export default {
  name: "OrderSendToInput",
  mixins: [ValidationMixin],
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    errors: null,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      email: null,
      error: null,
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    addEmail() {
      this.error = "";
      const email = this.email ? this.email.trim() : "";
      this.pushEmail(email);
    },
    pushEmail(email) {
      if (email.length === 0) return;
      if (this.emailValidator(email) === true) {
        this.model.push(email);
        this.email = null;
      } else {
        this.error = "Email not valid";
      }
    },
    removeEmail(index) {
      this.model.splice(index, 1);
    },
    onPaste(event) {
      event.preventDefault();
      const data = event.clipboardData.getData("text");
      if (!data) return;
      const emails = data.split(/[ ,]/);
      emails.forEach((email) => this.pushEmail(email));
    },
  },
};
</script>

<template>
  <v-text-field
    v-model="email"
    :error-messages="errors || error"
    :persistent-placeholder="modelValue.length > 0"
    :rules="rules"
    class="required"
    color="primary"
    density="compact"
    hide-details="auto"
    label="Send updates to"
    variant="outlined"
    @blur="addEmail"
    @paste="onPaste"
    @keyup.enter="addEmail"
  >
    <template #prepend-inner>
      <div class="d-flex flex-wrap row-gap-1 py-2">
        <v-chip
          v-for="(item, idx) in model"
          :key="item + idx"
          :closable="true"
          class="mr-1"
          close-icon="mdi-close"
          size="small"
          @click:close="removeEmail(idx)"
        >
          {{ item }}
        </v-chip>
      </div>
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
:deep(.v-field) {
  display: flex;
  flex-wrap: wrap;
}
</style>
