<script>
import OmniTextarea from "@/components/inputs/Textarea.vue";
import { OrdersMixin } from "@/views/orders/orders.mixin";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import { FacilityType } from "@/data/facility";
import OrderStopStatus from "@/components/status/OrderStopStatus.vue";
import { OrderFreightState, OrderStopState } from "@/data/order";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";

export default {
  name: "OrderViewStops",
  components: { FacilityInfo, OrderStopStatus, OmniTextarea },
  mixins: [GoogleMapsMixin, OrdersMixin],
  props: {
    order: Object,
  },
  computed: {
    orderFreights() {
      return this.order.freights.map((f, index) => ({
        ...f,
        number: index + 1,
      }));
    },
  },
  methods: {
    getFacilityMapUrl(facility) {
      if (facility.type === FacilityType.point && facility.preciseCoordinates) {
        return this.getPlaceUrl(facility.preciseCoordinates);
      }
      return this.getPlaceUrlByAddress(
        facility.addressLine ? facility.addressLine + ", " + facility.address : facility.address,
      );
    },
    getStopFreights(stop) {
      return this.orderFreights.filter((f) => stop.freights.includes(f.id));
    },
    getStopLabel(stop) {
      if (stop.isDeleted) {
        return "deleted";
      }
      if (stop.state === OrderStopState.new) {
        return "new";
      }
      if (
        stop.state === OrderStopState.updated ||
        this.getStopFreights(stop).some((f) => f.state !== OrderFreightState.normal || f.isDeleted)
      ) {
        return "updated";
      }
      return null;
    },
    getFreightClass(freight) {
      return {
        "freight--new": freight.state === OrderFreightState.new,
        "freight--updated": freight.state === OrderFreightState.updated,
        "freight--deleted": freight.isDeleted,
      };
    },
  },
};
</script>

<template>
  <div class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4">
    <v-row>
      <v-col>
        <div class="text-grey-darken-3 mb-6 font-weight-medium">Stops</div>
        <template v-for="(stop, index) in order.orderStops" :key="stop.id">
          <v-row data-qa="view-stop-row">
            <v-col class="timeline-col">
              <div class="timeline">
                <div class="timeline--bullet">{{ index + 1 }}</div>
                <div v-if="index < order.orderStops.length - 1" class="timeline--line"></div>
              </div>
            </v-col>
            <v-col>
              <div :class="'stop--' + getStopLabel(stop)" class="bg-grey-lighten-4 rounded pa-4">
                <v-row>
                  <v-col>
                    <div class="d-flex align-center font-size-12 mb-3">
                      <template v-if="stop.type === STOP_PICK_UP">
                        <v-icon :color="stop.isDeleted ? 'grey' : 'success'" size="20">
                          mdi-package-variant-closed
                        </v-icon>
                        <span
                          :class="stop.isDeleted ? 'text-grey' : 'text-blue-grey-darken-1'"
                          class="font-weight-600 ml-2"
                        >
                          Pick up
                        </span>
                      </template>
                      <template v-if="stop.type === STOP_DELIVERY">
                        <v-icon :color="stop.isDeleted ? 'grey' : 'error'">mdi-map-marker-radius-outline</v-icon>
                        <span
                          :class="stop.isDeleted ? 'text-grey' : 'text-blue-grey-darken-1'"
                          class="font-weight-600 ml-2"
                        >
                          Delivery
                        </span>
                      </template>
                      <order-stop-status :stop="stop" class="ml-2"></order-stop-status>
                    </div>
                    <div
                      :class="stop.isDeleted ? 'text-grey' : 'text-grey-darken-2'"
                      class="mb-2 text-caption"
                      data-qa="facility-type"
                    >
                      Type: {{ facilityTypesMap[stop.facility.type] }}
                    </div>
                    <facility-info :disabled="stop.isDeleted" :facility="stop.facility" class="mb-2"></facility-info>
                    <div
                      :class="stop.isDeleted ? 'text-grey' : 'text-grey-darken-1'"
                      class="d-flex align-start font-weight-500 mb-5"
                      data-qa="freight-time"
                    >
                      <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
                      <div>
                        <div>
                          {{ getTimeType(stop.timeType) }}:
                          {{ getTimeInterval(stop.timeFrom, stop.timeTo, stop.timezone) }}
                        </div>

                        <v-tooltip v-if="stop.time2To" location="top">
                          <template #activator="{ props }">
                            <div v-bind="props">
                              {{ getTimeType(stop.time2Type) }}:
                              {{ getTimeInterval(stop.time2From, stop.time2To, stop.timezone) }}
                            </div>
                          </template>
                          <span>Second time frame</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div>
                      <div
                        v-for="freight in getStopFreights(stop)"
                        :key="freight.id"
                        :class="!stop.isDeleted ? getFreightClass(freight) : ''"
                        data-qa="freight-data"
                      >
                        <span :class="stop.isDeleted ? 'text-grey' : 'text-grey-darken-1'" class="mr-2">
                          Freight #{{ freight.number }}:</span
                        >
                        <span :class="stop.isDeleted ? 'text-grey' : 'text-grey-darken-2'">
                          {{ freight.quantity }} {{ getFreightTypeName(freight)?.toLowerCase() }}
                          {{ freight.weight }} lbs
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col class="position-relative">
                    <div v-if="getStopLabel(stop)" class="updated-label">
                      <span class="label">{{ getStopLabel(stop) }}</span>
                    </div>
                    <omni-textarea
                      :disabled="stop.isDeleted"
                      :model-value="stop.note"
                      data-qa="load-stop-note"
                      label="Order stop note"
                      readonly
                      rows="6"
                    >
                    </omni-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <div v-if="index < order.orderStops.length - 1" class="distance-gap">
            <div class="distance-gap--distance text-grey-darken-1">
              {{ getStopDistance(order.orderStops, order.distances, index) }} miles
            </div>
            <div class="distance-gap--line"></div>
          </div>
        </template>
        <div class="text-grey-darken-1 font-size-12 total-distance">
          Loaded miles: {{ $filters.miles(order.totalDistance) }} miles
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.timeline-col {
  flex: 0 0 16px;
  margin-right: 20px;
}

.timeline {
  position: relative;
  height: 100%;

  .timeline--bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 20px;
    background-color: #9e9e9e;
    color: white;
    font-size: 10px;
    font-weight: 500;
  }

  .timeline--line {
    position: absolute;
    left: 7px;
    top: 26px;
    width: 2px;
    bottom: -42px;
    background-color: #e0e0e0;
  }
}

.distance-gap {
  position: relative;
  margin: 10px 0 10px 44px;
  height: 12px;

  .distance-gap--distance {
    position: absolute;
    background-color: #ffffff;
    padding: 0 12px;
    top: -5px;
    left: 45%;
    font-size: 10px;
    font-weight: 500;
    z-index: 10;
  }

  .distance-gap--line {
    position: absolute;
    top: 5px;
    height: 1px;
    width: 100%;
    background-color: #e0e0e0;
  }
}

.total-distance {
  margin: 7px 44px;
}

.freight--deleted {
  text-decoration-line: line-through;
  text-decoration-color: $grey-lighter-contrast;

  span {
    color: $grey-lighter-contrast !important;
  }

  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $grey-lighter-contrast;
  }
}

.freight--updated {
  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $orange;
  }
}

.freight--new {
  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $green;
  }
}

.stop--updated,
.stop--new,
.stop--deleted {
  border: 1px solid;

  .updated-label {
    height: 28px;

    .label {
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;
      padding: 8px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 4px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -4px;
      margin-right: -4px;
    }
  }
}

.stop--updated {
  border-color: $orange;

  .label {
    background-color: $orange;
  }
}

.stop--new {
  border-color: $green;

  .label {
    background-color: $green;
  }
}

.stop--deleted {
  border-color: $grey;

  .label {
    background-color: $grey;
  }
}
</style>
