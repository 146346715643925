export enum OrderStatus {
  planned = "planned",
  unassigned = "new",
  inProgress = "in-progress",
  cancelled = "cancelled",
  completed = "completed",
  tonu = "tonu",
  waitingTonu = "wait-tonu",
  noTonu = "no-tonu",
}

export type OrderStatusMapItem = { id: OrderStatus; name: string; forCancellation?: boolean };
export type OrderStopStatusMapItem = { id: OrderStopStatus; name: string };
export type OrderStopStateMapItem = { id: OrderStopState; name: string };
export type OrderStopTypeMapItem = { id: OrderStopType; name: string };
export type OrderStopTimeTypeMapItem = { id: OrderStopTimeType; name: string };
export type OrderFreightTypeMapItem = { id: OrderFreightType; name: string };

export const OrderStatusMap: OrderStatusMapItem[] = [
  {
    id: OrderStatus.planned,
    name: "Planned",
  },
  {
    id: OrderStatus.unassigned,
    name: "Unassigned",
  },
  {
    id: OrderStatus.completed,
    name: "Completed",
  },
  {
    id: OrderStatus.waitingTonu,
    name: "Waiting for TONU",
    forCancellation: true,
  },
  {
    id: OrderStatus.tonu,
    name: "TONU",
    forCancellation: true,
  },
  {
    id: OrderStatus.cancelled,
    name: "Cancelled",
    forCancellation: true,
  },
  {
    id: OrderStatus.inProgress,
    name: "In progress",
  },
  {
    id: OrderStatus.noTonu,
    name: "No TONU",
  },
];

export const OrderCancellationStatusMap: OrderStatusMapItem[] = OrderStatusMap.filter((s) => s.forCancellation);

export enum OrderStopTimeType {
  fcfs = "f",
  asap = "as",
  direct = "d",
  appointment = "ap",
}

export const OrderStopTimeTypeMap: OrderStopTimeTypeMapItem[] = [
  {
    id: OrderStopTimeType.fcfs,
    name: "FCFS",
  },
  {
    id: OrderStopTimeType.asap,
    name: "ASAP",
  },
  {
    id: OrderStopTimeType.direct,
    name: "Direct",
  },
  {
    id: OrderStopTimeType.appointment,
    name: "Appointment",
  },
];

export enum OrderStopType {
  pickup = "p",
  delivery = "d",
  reload = "r",
}

export const OrderStopTypeMap: OrderStopTypeMapItem[] = [
  {
    id: OrderStopType.pickup,
    name: "Pick up",
  },
  {
    id: OrderStopType.delivery,
    name: "Delivery",
  },
];

export enum OrderFreightType {
  pallets = "pl",
  pieces = "pc",
}

export const OrderFreightTypeMap: OrderFreightTypeMapItem[] = [
  {
    id: OrderFreightType.pallets,
    name: "Pallets",
  },
  {
    id: OrderFreightType.pieces,
    name: "Pieces",
  },
];

export enum OrderStopStatus {
  notStarted = "new",
  inProgress = "in-progress",
  partiallyCompleted = "part-completed",
  completed = "completed",
}

export const OrderStopStatusMap: OrderStopStatusMapItem[] = [
  {
    id: OrderStopStatus.notStarted,
    name: "Not started",
  },
  {
    id: OrderStopStatus.inProgress,
    name: "In progress",
  },
  {
    id: OrderStopStatus.partiallyCompleted,
    name: "Partially completed",
  },
  {
    id: OrderStopStatus.completed,
    name: "Completed",
  },
];

export enum OrderStopState {
  normal = "normal",
  new = "new",
  updated = "updated",
}

export const OrderStopStateMap: OrderStopStateMapItem[] = [
  {
    id: OrderStopState.normal,
    name: "Normal",
  },
  {
    id: OrderStopState.new,
    name: "New stop",
  },
  {
    id: OrderStopState.updated,
    name: "Updated",
  },
];

export enum OrderFreightState {
  normal = "normal",
  new = "new",
  updated = "updated",
}
