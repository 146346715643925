<script>
import OmniDialog from "@/components/OmniDialog.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { OrderStopType } from "@/data/order";
import { OrdersMixin } from "@/views/orders/orders.mixin";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "CheckInAction",
  components: { OmniSelect, TimePicker, DatePicker, OmniDialog },
  mixins: [ValidationMixin, DateTimeMixin, OrdersMixin],
  inheritAttrs: false,
  props: {
    trip: Object,
    route: Object,
    stop: Object,
  },
  emits: ["checkedIn"],
  data() {
    return {
      showDialog: false,
      loading: false,
      checkInData: {},
    };
  },
  computed: {
    stopType() {
      switch (this.stop.type) {
        case OrderStopType.pickup:
          return "Pick up";
        case OrderStopType.delivery:
          return "Delivery";
        default:
          return "";
      }
    },
  },
  watch: {
    async showDialog(value) {
      if (value) {
        this.checkInData.timezone = this.stop.facility.timezone;
        const date = this.$dayjs().tz(this.checkInData.timezone);
        this.checkInData.date = date.format("YYYY-MM-DD");
        this.checkInData.time = date.format("HH:mm");
      }
    },
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    showCheckInConfirmation() {
      this.showDialog = true;
    },
    async checkIn() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      const response = await this.$api.trips.checkInRouteStop(this.trip.id, this.stop.id, {
        checkInTime: this.dateToUTC(this.checkInData.date + " " + this.checkInData.time, this.checkInData.timezone),
      });
      if (response.success) {
        this.$emit("checkedIn");
        this.showDialog = false;
      } else {
        this.showSnackbarError("Error checking in at stop");
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-btn
    block
    class="text-uppercase font-weight-600"
    color="primary"
    v-bind="$attrs"
    variant="flat"
    @click="showCheckInConfirmation"
  >
    Check in
  </v-btn>
  <omni-dialog
    v-model="showDialog"
    :disabled="loading"
    :loading="loading"
    :title="'Check in on the ' + stopType"
    primary-action-label="Check in"
    @primary-action:click="checkIn"
  >
    <v-form ref="form" class="py-6">
      <div class="mb-4 text-grey-darken-1">
        You are about to check in
        <router-link
          v-if="route.$truck.id"
          :to="{ name: 'truck-view', params: { id: route.$truck.id } }"
          class="text-primary text-decoration-none"
          data-qa="card-truck-number"
          target="_blank"
        >
          Truck #{{ route.$truck?.number }}
        </router-link>
        at the {{ stopType }}:
      </div>
      <div class="rounded bg-grey-lighten-4 pa-2 mb-8">
        <router-link
          :to="{ name: 'facilities-edit', params: { id: stop.facility?.id } }"
          class="text-primary font-weight-500 text-decoration-none d-block"
          data-qa="card-facility-name"
          target="_blank"
        >
          {{ stop.facility?.name }}
        </router-link>
        <div class="mt-1 font-size-12 text-grey-darken-2" data-qa="card-facility-address">
          {{ stop.facility?.addressLine }}, {{ stop.facility?.address }}
        </div>
      </div>
      <v-row>
        <v-col cols="4">
          <omni-select
            v-model="checkInData.timezone"
            :items="timezonesShortList"
            :rules="[requiredValidator]"
            data-qa="timezone"
            label="Time zone"
            required
          />
        </v-col>
        <v-col cols="5">
          <date-picker v-model="checkInData.date" label="Date" required></date-picker>
        </v-col>
        <v-col cols="3">
          <time-picker v-model="checkInData.time" label="Time" required></time-picker>
        </v-col>
      </v-row>
    </v-form>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
